.pat-head-mypro {
  font-size: 20px;
  font-weight: 500;
  color: #245c66;
  font-family: DM Sans, sans-serif;
}
.pat-head-mypro1 {
  font-size: 16px;
  font-weight: 600;
  font-family: DM Sans, sans-serif;
  margin-top: 10px;
  color: #2d3748;
}
.save-info-set {
  margin-top: 29px;
}

.btn-delete-set {
  padding: 0px;
  background: white;
  border: 1px;
}
/* .rx-pro-card{
  height: 150px;
  overflow: auto;
} */
.rx-pro-cd {
  cursor: poDM Sans;
  transition-duration: 0.3s;
}
.rx-pro-cd:hover {
  color: #16424a;
  cursor: poDM Sans;
}
.fv-pr-sig-icon {
  margin-bottom: 4px;
  cursor: poDM Sans;
}
.fv-pr-sig-icon:hover {
  cursor: poDM Sans;
  color: #16424a;
}
.btn-delete-set svg {
  height: 25px;
  width: 25px;
  color: red;
}
.btn-delete-set:hover {
  background-color: #ffffff;
}
.ant-checkbox-wrapper {
  margin-left: 12px;
}
.check-left .ant-checkbox-wrapper {
  margin-left: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}
.del-btn-arrange {
  margin-top: -15px;
  margin-left: 15px;
}

.add-num-set {
  text-align: initial;
  margin-left: 20px;
  margin-top: 15px;
}

.add-user-team {
  text-align: end;
  margin-right: 20px;
}
.input-mid-text {
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -3px;
  height: 30px;
}

.security-check .ant-checkbox-checked .ant-checkbox-inner::after {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #245c66 !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
  top: 50% !important;
  left: 50% !important;
  width: 8px !important;
  height: 8px !important;
  background-color: #245c66 !important;
  border: 1px solid #d9d9d9 !important;
  border: 0 !important;
  transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1 !important;
  content: " " !important;
}
.security-check .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #245c66 !important;
}

.security-check:hover {
  border: #245c66 !important;
}

.radiobtn input[type="radio"]:checked {
  accent-color: #245c66;
}

.radio-input-box span:nth-child(2) {
  display: flex !important;
}

.radio-input-box span:nth-child(2) input {
  margin-left: 10px;
  margin-top: -5px;
}

.sm-text-arrange {
  margin-top: 3px;
  margin-left: 5px;
}

.services-list {
  border-top: 1px solid #cbd5e0;
  /* border-bottom: 1px solid #cbd5e0; */
  padding-top: 12px;
  padding-bottom: 12px;
}
.cal-set-w {
  max-width: 15.6666665% !important;
}
.m-w--2 {
  max-width: 2% !important;
  padding-top: 5px;
}
.email-disable {
  border: 1px solid #cbd5e0;
  padding: 1rem 15px;
  border-radius: 7px;
}

.switch-align-email {
  margin-left: auto;
  margin-top: 5px;
}

.mesg-left {
  color: #b7c1cc;
  width: 100px;
}
#document-popup .mesg-left {
  color: #2d3748;
  width: 55px;
}
.intake-forms tr td {
  border-right: 1px solid #cbd5e0;
}
.intake-forms tr th {
  border-right: 1px solid #cbd5e0;
}
.intake-forms tr th:last-child {
  border-right: 0px solid #cbd5e0;
}
.intake-forms tr td:last-child {
  border-right: 0px solid #cbd5e0 !important;
}

.four-actions {
  padding-left: 0px;
  padding-right: 0px;
  width: 250px;
}

.print-intake {
  margin-left: 84px;
}

.color-sel-btn {
  background-color: white;
  border: 0px;
}

.color-sel-btn span {
  background-color: #57bf7f;
  border: 0px;
}

.rate-service {
  padding-left: 0px;
}

.default-d-service {
  padding-right: 30px;
}

.min-align {
  margin-top: 34px;
  margin-left: -25px;
}

.services-block-set {
  width: 500px;
  margin-left: 88px;
}

.service-check {
  margin-top: 34px;
  margin-left: 10px;
}
.add-user-nut {
  float: right;
  margin-top: -45px;
  /* padding-top: 4px; */
}
.add-user-nuta {
  float: right;
  margin-top: -56px;
  /* padding-top: 4px; */
}
.space-non {
  width: 100% !important;
  margin-left: 0px !important;
}
.th-r-no {
  border-right: 0px solid #cbd5e0 !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-left: 20px;
  padding-right: 0px;
}
.wid-space {
  width: 230%;
}
.retri {
  margin-top: -5px;
  margin-right: 5px;
}
.fs {
  font-size: 18px;
}
.doc-invoice-head {
  font-size: 18px;
  color: #245c66;
  font-weight: 600;
  position: absolute;
  margin-left: 19px;
  font-family: DM Sans, sans-serif;
}
.doc-invoice-logo {
  text-align: start;
  margin-left: 8px;
}
.doc-invoice-logo-check {
  font-size: 16px;
}
.doc-invoice-footer {
  text-align: start;
  margin-left: 20px;
  font-size: 16px;
  margin-top: 12px;
  margin-right: 10px;
  width: 440px;
}
.doc-invoice-mail-top {
  margin-top: 20px;
  text-align: start;
  margin-left: 20px;
  margin-right: 10px;
}
.ui.card,
.ui.cards > .card {
  width: auto;
}
.cont-title {
  height: 58px;
  padding-top: 19px;
}
.pad {
  margin: 0;
  font-size: 1em;
  font-weight: 700;
  color: #708693;
}
.invoice-email-head {
  padding-left: 75px;
  color: #444;
}
.invoice-email-head1 {
  padding-left: 62px;
  color: #444;
  padding-top: 2px;
}
.invoice-email-head7 {
  padding-left: 50px;
  color: #444;
  padding-top: 2px;
}
.invoice-email-head2 {
  padding-left: 112px;
  color: #444;
  padding-top: 2px;
}

.padall {
  padding-left: 30px;
}
.hr-width {
  margin-left: 20px;
  margin-right: 10px;
}
.doc-Save-btn {
  text-align: start;
  margin-left: 20px;
  padding-bottom: 15px;
  padding-top: 10px;
}
.btn-save {
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  padding: 8px 16px;
}
.modal-edit {
  text-align: end;
  margin-right: 20px;
  font-size: 17px;
  margin-top: -39px;
  padding-bottom: 12px;
  color: #245c66;
  cursor: poDM Sans;
}
#document-popup .modal-header {
  display: block;
}
.modal-tit {
  margin-left: 42px;
  font-size: 19px;
  color: #000000;
}
.modal-btn {
  float: right;
  margin-top: 10px;
  font-size: 18px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 30px;
  border-radius: 7px;
  font-weight: 600;
}

.modal-dialog {
  /* max-width: 600px; */
  /* margin-right: 14px;
  height: auto; */
}

.body-title {
  font-size: 22px;
  margin-left: 56px;
  font-weight: 600;
}
.form-from-input {
  margin-left: 54px;
  font-size: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.form-control {
  height: 34px;
}
.font-texts {
  font-family: DM Sans, sans-serif;
  color: rgba(0, 0, 0, 0.85);
}
.bsinfo svg {
  font-size: 20px;
  margin-left: 6px;
  margin-bottom: 2px;
}

.toolstip {
  position: relative;
  display: inline-block;
}

.toolstip .tooltiptext {
  visibility: hidden;
  width: 388px;
  background-color: rgb(45, 55, 72);
  color: #fff;
  /* text-align: center; */
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 95%;
  left: -679%;
  /* margin-left: -60px; */
}

.toolstip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.toolstip:hover .tooltiptext {
  visibility: visible;
}
.toolstip:hover {
  color: #245c66;
}

.doc-invoice-footer .form-control {
  width: 150%;
}
#document-popup .modal-body {
  margin-left: 10px;
  padding-right: 12px !important;
  padding: 0px;
}
.client-popup #document-popup .modal-body {
  margin-left: 15px;
  margin-right: 15px;
  padding: 0px;
}
/* #document-popup .modal-header {
  padding: 0px;
  height: 65px;
} */
#document-popup .blue-color .form-control {
  width: 508px;
}
.mar-top {
  margin-top: 25px;
}
.doc-footer .prob-top-layer {
  padding-bottom: 0px !important;
  margin-bottom: -10px;
}
.doc-footer {
  margin-bottom: 20px;
}

#document-popup .modal-dialog {
  position: absolute;
  width: 700px;
  height: 650px;
  left: 855px;
  top: 20px;
}
#document-popup .head-tit {
  margin-top: 15px;
  /* margin-bottom: 4px; */
  color: #000000;
}
#document-popup .form-label {
  color: #000000;
  font-weight: 500;
}
#document-popup .form-control {
  color: #000000;
  font-weight: 500;
}
#document-popup .form-control {
  height: 30px;
}
#document-popup .modal-content {
  border-radius: 8px !important;
}
.btn-hide {
  display: none;
}
#document-popup .modal-footer {
  align-self: auto;
  padding: 16px 1px 17px 15px;
  margin-top: 4px;
}
.client-popup #document-popup .modal-footer {
  align-self: auto;
  padding: 16px 1px 20px 20px !important;
  margin-top: 4px;
}
#document-popup .footer-btn {
  height: 32px;
  width: 80px;
  border-radius: 4px;
  padding: 0.25rem 0.75rem;
}
.edit-email {
  font-weight: 600;
  font-size: 15px;
}
.pad-left-right {
  margin: 0px 8px 0px 8px;
}
.prob-head-title {
  font-size: 20px !important;
  color: #2e2c34 !important;
  font-family: DM Sans, sans-serif;
  font-weight: 400;
  line-height: 24.2px;
}
#demographics .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #245c66 !important ;
}
.ant-tabs-tab {
  font-size: 16px !important;
}
.tb .ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 13px 0 !important;
}
.tb .p-b-t-10 {
  padding-bottom: 5px !important;
  padding-top: 15px !important;
}
.tb .m-l-r--20 {
  margin-top: 2rem;
}
.modal-footer {
  align-self: auto !important;
  padding-left: 4% !important;
}
.text-dec {
  text-decoration: none;
}
.modal-footer .close-btn {
  width: 79px;
  height: 32px;
  /* color: #245C66;
  border-color: #245C66; */
  background-color: #fff;
}

.modal-footer .save-btn:not(.role-save-btn) {
  width: 79px;
  height: 32px;
  /* background-color: #245C66; */
}
.close-btn {
  width: 79px;
  height: 32px;
  background-color: #f6f7f9;
  font-weight: 500;
}

.save-btn {
  height: 32px;
  background-color: #245c66 !important;
  border-color: #245c66 !important;
  font-weight: 500;
}
.save-btn:hover {
  background-color: #16424a !important;
  border-color: #16424a !important;
}

/* .footer-border-none {
  margin: 0px 53px 21px !important;
} */
.text-lef .text-al {
  text-align: left !important;
}
#demographics .ant-tabs-tab:hover {
  color: #245c66;
  font-weight: 700px;
}
#demographics .prob-head-title {
  color: #245c66 !important;
}
.vb .ant-checkbox-wrapper {
  margin-left: 0px !important;
}
/* @media (min-width:1025px) and (max-width:1168px) {
  .client-popup .modal-dialog {
    left: 530px !important;
    top: 7px !important;
}
} */
.check-tex {
  margin-left: 15px;
}
.icon-bott svg {
  margin-bottom: 25px;
}

.right-block .appt-block-main {
  padding-bottom: 9px;
}
.p-l-27 {
  padding-left: 27px;
}
.p-b-16 {
  padding-bottom: 16px;
}
#sec-block .form-control {
  height: 26px;
}
#sec-block .input-mid-text {
  width: 56px;
  text-align: center;
}
.m-t-32 {
  margin-top: 15px;
  margin-bottom: 25px;
}
#sec-block .create-appt {
  font-weight: 500;
  background-color: #245c66;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 4px;
  line-height: 18px;
}
/* .ant-checkbox-wrapper-checked{
  background-color: red;
  border-color: red;
} */
.security-check .ant-checkbox-checked .ant-checkbox-inner::after {
  background-color: #ffffff !important;
  border-radius: 50%;
}

.ant-checkbox-inner {
  border-radius: 4px !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  background-color: #ffffff !important;
  border-radius: 50%;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  border-collapse: separate;
  transition: all 0.3s;
  top: 50% !important;
  left: 50% !important;
  width: 8px !important;
  height: 8px !important;
  border: 1px solid #d9d9d9 !important;
  border: 0 !important;
  transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1 !important;
  content: " " !important;
}
.sp .create-appt {
  height: 40px;
  background: #245c66;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.ss .create-appt {
  height: 38px;
  background: #245c66;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.pat-para {
  font-size: 14px;
}
#billsetting .create-appt {
  height: 35px;
  background: #245c66;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 4px 0px 8px 0px;
}
.fade .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.fade.modal .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.7s linear, right 0.7s ease-out;
  -moz-transition: opacity 0.7s linear, right 0.7s ease-out;
  -o-transition: opacity 0.7s linear, right 0.7s ease-out;
  transition: opacity 0.7s linear, right 0.7s ease-out;
}
.fade.modal.show .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.7s linear, right 0.7s ease-out;
  -moz-transition: opacity 0.7s linear, right 0.7s ease-out;
  -o-transition: opacity 0.7s linear, right 0.7s ease-out;
  transition: opacity 0.7s linear, right 0.7s ease-out;
}

user-guide-popup-box .user-btns button {
  margin: 10px 15px 10px 0px;
}
.appt-remin .ant-space {
  margin: 0px;
}
.appt-remin-btn {
  margin: 20px 0px 13px 0px;
}

.p-t-15 {
  padding-top: 15px;
}
.cal-sett .form-control {
  width: 161px;
}
.appt-head > th {
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
}
.tb-m-t-0 {
  margin-top: 0px !important;
}
.add-fac {
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  padding: 6px 12px;
  height: 34px;
}

#add-facility .modal-header {
  padding: 13px 20px 13px 20px;
}
#add-facility .modal-title {
  margin-top: 7px;
  margin-bottom: 7px;
}
#add-facility .modal-body {
  margin-top: 2px;
  margin-bottom: -10px;
}
#add-facility .modal-footer {
  padding: 16px 7px 17px 1px;
  margin-left: 1px;
}
.alert-popup-message .modal-dialog {
  transition: opacity 0.7s linear, center 0.7s ease-out !important;
  transform: translate(0, -50px) !important;
  height: 0px !important;
  width: 395px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  border: none !important;
  left: 0;
  right: 0;
  float: none;
  top: 50%;
}
#alert-popup-message .modal-backdrop {
  background: none;
}
.alert-popup-message .modal-content {
  background: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
}
.msg {
  margin-bottom: 0px !important;
  height: auto;
  border-radius: 8px;
  /* vertical-align: middle; */
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-weight: 500;
}
.icons-bs {
  font-size: 30px;
  padding-right: 5px;
}
#user-staff .padin {
  padding-top: 15px !important;
}
.m-t-13 {
  margin-top: 13px;
}
.ant-checkbox-checked::after {
  border-radius: 4px !important;
}
/* .facility-add .modal-dialog {
  height: 543px;
} */
.facility-add .modal-content {
  border: none;
}
/* .user-add .modal-dialog {
  height: 586px;
} */
.user-add .modal-content {
  border: none;
}
.user-add .modal-title {
  margin-top: 0px;
}
.user-add .modal-footer {
  padding-left: 6% !important;
}
.appt-remin-email .modal-header {
  padding: 14px 20px 13px 20px !important;
}

/* .appt-remin-email .modal-dialog {
  height: 727px;
} */
.appt-remin-email .modal-content {
  border: none;
}
.appt-remin-email .modal-title {
  margin-top: 0px;
}
.appt-remin-email .modal-footer {
  padding: 16px 20px 17px 16px !important;
}
/* .appt-remin-voice .modal-dialog {
  height: 560px;
} */
.appt-remin-voice .modal-header {
  padding: 14px 20px 13px 20px !important;
}
.appt-remin-voice .modal-content {
  border: none;
}
.appt-remin-voice .modal-title {
  margin: 0px !important;
}
.appt-remin-voice .modal-header .close {
  margin-top: -25px;
}
.appt-remin-voice .modal-body {
  margin-left: 20px !important;
}
.client-popup #document-popup .modal-footer {
  padding: 16px 20px 17px 16px !important;
}
/* .appt-remin-text .modal-dialog {
  height: 560px;
} */
.appt-remin-text .modal-content {
  border: none;
}
.appt-remin-text .modal-title {
  margin: 0px !important;
}
.appt-remin-text .modal-header {
  padding: 14px 20px 13px 20px !important;
}
.appt-remin-text .modal-header .close {
  margin-top: -25px;
}
.appt-remin-text .modal-body {
  margin-left: 20px !important;
}
.appt-remin-text .modal-footer {
  padding: 16px 20px 17px 16px !important;
}
/* .document-invoice-email .modal-dialog {
  height: 706px;
} */
.document-invoice-email .modal-content {
  border: none;
}
.document-invoice-email .modal-title {
  margin: 0px !important;
}
.document-invoice-email .modal-header .close {
  margin-top: -25px;
}
.document-invoice-email .modal-header {
  padding: 11px 20px 14px 19px !important;
}
.document-invoice-email .modal-body {
  margin-left: 12px !important;
}
.document-invoice-email .modal-footer {
  padding-left: 17px !important;
}
/* .document-statement-email .modal-dialog {
  height: 704px;
} */
.document-statement-email .modal-content {
  border: none;
}
.document-statement-email .modal-title {
  margin: 0px !important;
}
.document-statement-email .modal-header .close {
  margin-top: -25px;
}
.document-statement-email .modal-header {
  padding: 11px 20px 14px 19px !important;
}
.document-statement-email .modal-body {
  margin-left: 20px !important;
}
.document-statement-email .modal-footer {
  padding-left: 17px !important;
}
/* .document-superbill-email .modal-dialog {
  height: 704px;
} */
.document-superbill-email .modal-content {
  border: none;
}
.document-superbill-email .modal-title {
  margin: 0px !important;
}
.document-superbill-email .modal-header .close {
  margin-top: -25px;
}
.document-superbill-email .modal-header {
  padding: 11px 20px 14px 19px !important;
}
.document-superbill-email .modal-body {
  margin-left: 20px !important;
}
.document-superbill-email .modal-footer {
  padding-left: 17px !important;
}
.m-b-3 {
  margin-bottom: 3px;
}
.m-t-22 {
  margin-top: 22px;
}
.facility_finfo .prob-head-title {
  line-height: 40px;
}
#doc-checks .prob-top-layer {
  padding-left: 19px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 8px;
}
.bill_sett .prob-top-layer {
  padding-left: 19px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 7px;
}
.sett_service .prob-top-layer {
  padding-left: 19px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 15px;
}
.m-l-20-r-25 {
  margin-left: 20px;
  margin-right: 25px;
}
.calendar_sett .prob-top-layer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 24px;
}

.calendar_sett {
  margin-bottom: 10px;
}
.tb .prob-top-layer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 7px;
}
.client_portal .prob-top-layer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 7px;
}
.p-b-20 {
  padding-bottom: 20px;
}
#sec-block .prob-top-layer {
  padding: 11px 20px 12px 19px;
}
.p-l-20 {
  padding-left: 20px;
}
.access_denied_container {
  width: 1000px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto !important;
  margin-bottom: auto;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-top: 4%;
  display: flex;
}

.permission_main_text {
  font-size: 26px;
  font-family: DM sans, sans-serif;
  font-weight: 600;
  text-align: left;
  color: #000000;
  line-height: 30.5px;
}
.permission_sub_text {
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
}
.perission_bo_back_btn {
  text-align: left;
  margin-top: 21px;
}
.go_back_btnn {
  padding-right: 18px;
  padding-left: 18px;
  font-weight: 600;
  line-height: 24px;
}
.access_image_container {
  margin-left: auto;
}
.permission_denied_text_content {
  margin-left: auto;
}
.payer_label {
  width: 50%;
  color: #718096;
}
.payer_add_btn_ {
  font-weight: 500;
  font-size: 14px;
}
.add_icon_ {
  font-size: 21px;
}
.add_new_payer .modal-dialog {
  height: 437px;
}
.payer_layer {
  padding-left: 24px !important;
  padding-top: 24px !important;
  display: flex;
  padding-bottom: 24px !important;
  align-items: center;
}
.fav-chart-part {
  display: flex;
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 20px;
  margin-bottom: 25px;
}

.fav-chart-part .card {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-radius: 8px;
  border: none;
  height: 100%;
}
.fav-chart-part .card-header {
  background: #fafafa;
  text-align: left;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-right: 80px;
}
.two_progress_bar-1 {
  display: flex;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}
.two_progress_bar-1 {
  display: flex;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}
.fav_title {
  float: left !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 25px;
  margin-top: 10px;
}
.rx_profil_chart-2,
.rx_profile_settings {
  width: 20%;
  margin: 20px 40px 40px 0px;
}
.rx_profil_chart-2 {
  border: 1px dashed #c1c8d2;
  margin-left: 1.1%;
  width: 29% !important;
  border-radius: 2% !important;
}
.rx_profil_chart-2 .card-header {
  border-bottom: none;
}
.rx_profil_chart-2 .card-body {
  text-align: left;
  padding: 5px 0px 30px 20px;
}
.rx_profil_chart-2 .card-body .list-group {
  margin-top: 38px;
}
.rx_profil_chart-2 .card-body .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 123px;
  font-weight: 500;
  color: #245c66 !important;
}
.rx_profile_settings {
  margin-right: 1.1%;
}
.progress_bar_insurance_ {
  height: 25px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.rx_profile_settings .card-body {
  text-align: left;
  padding: 5px 21px 0px 0px;
}
.rx_profile_settings .card-body .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.rx_profile_settings .card-body .list-group {
  margin-bottom: 25px;
}
.rx_profile_settings .card-header {
  background: #fafafa !important;
}
.prescription-fav-center-text {
  width: 93% !important;
  text-align: center !important;
}
.rx_profile_text {
  color: #245c66;
  font-weight: 500;
  float: right;
}
.rx_pro_icon_1_ {
  font-size: 20px !important;
  color: #3e8cf4 !important;
  margin-right: 4px !important;
  margin-top: -4px !important;
  color: white;
}
.rx_profile_background {
  display: flex;
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 190px;
  margin-bottom: 150px;
}
.rx_profile_background1 {
  display: flex;
  margin-bottom: 200px;
}
.add-fav-pres-btn {
  height: 32px;
  background-color: #245c66 !important;
  border-color: #245c66 !important;
  border-radius: 4px;
}
.favorite_prescriptions_chart-2 .card {
  border: none !important;
}
.favorite_prescriptions_chart-2,
.rx_profile_settings {
  width: 20%;
  margin-top: 20px;
}
.favorite_prescriptions_chart-2 {
  border: 1px dashed #c1c8d2;
  margin-left: 1.1%;
  width: 29% !important;
  border-radius: 2% !important;
}
.favorite_prescriptions_chart-2 .card-header {
  border-bottom: none;
}
.favorite_prescriptions_chart-2 .card-body {
  text-align: left;
  padding: 5px 0px 30px 20px;
}
.favorite_prescriptions_chart-2 .card-body .list-group {
  margin-top: 38px;
}
.favorite_prescriptions_chart-2 .card-body .list-group-item {
  padding-left: 0.75rem;
  border: none !important;
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 123px;
  font-weight: 500;
  color: #245c66 !important;
}
.rx_profil_chart-2 .card {
  border: none !important;
}
.threedots {
  margin-left: 478px !important;
}
.threedots-fav {
  float: right !important;
}
.fav_title_1 {
  margin-left: -80px !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 25px;
  margin-top: 10px;
}
.rx-pro-upload-btn {
  margin-top: 12px;
  position: absolute;
  text-align: right;
  z-index: 100;
  color: #2066c2;
  background-color: white;
}
.btn-light-2 {
  background-color: #ffff !important;
}
#sdss .modal-content {
  width: 600px !important;
}
.pres-container {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}
.phar-card-head {
  font-weight: 500;
  text-align: left;
}
.pres-middle {
  margin: 14px 40px 12px 0px;
}
.phar-labl {
  font-weight: 600;
  color: black;
  margin-top: 12px;
  text-align: left;
}
.pres-btn-1 {
  background-color: rgba(0, 165, 255, 0.1);
  color: #00a5ff;
}

.pres-btn-1,
.pres-btn-3 {
  padding: 2px 8px 4px;
  border-radius: 4px;
  border: none;
  height: 32px;
  margin-right: 12px;
}
.pres-btn-3 {
  background-color: #636d73;
  color: white;
}
.pres-btn-2 {
  border-radius: 4px;
  border: none;
  height: 32px;
  margin-right: 12px;
  width: 150px;
  background-color: #57bf7f;
  color: white;
}
.fav-prescription {
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
  margin-right: 24px;
  width: 300px;
  margin-bottom: 24px;
}
.pres-footer {
  display: flex;
  align-items: center;
  margin-top: 14px;
}
.fav-pres-top {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}
.pres-type-btn {
  height: 32px;
  background-color: #fafafa;
  margin: 0px 0px 20px 4px;
  color: black;
  border: none;
}
.subs-btn {
  padding: 6px 8px;
  width: 160px;
  border: none;
}
.btn-yes {
  background-color: rgba(32, 201, 172, 0.1);
  color: #20c9ac;
}
.btn-no {
  background-color: rgba(182, 180, 186, 0.1);
  color: #84818a;
}
.med-yellow {
  background-color: rgba(252, 52, 0, 0.1);
  color: #fc3400;
}
.med-orange {
  background-color: rgba(255, 160, 67, 0.1);
  color: #ffa043;
}
.med-blue {
  background-color: #c3d7db77;
  color: #245c66;
}
.med-blue:hover {
  background-color: #245c66;
  color: #fff;
}

.med-blue:focus,
.med-blue:active {
  background-color: #c3d7db77 !important;
  color: #245c66 !important;
  outline: none !important;
  box-shadow: none !important;
}

.outline_btn {
  background-color: transparent !important;
  border-color: #245c66;
  color: #245c66;
}

.outline_btn:hover {
  background-color: #245c66 !important;
  border-color: #245c66;
  color: #fff;
}
.outline_btn:focus,
.outline_btn:active {
  background-color: transparent !important;
  border-color: #245c66 !important;
  color: #245c66 !important;
  outline: none !important;
  box-shadow: none !important;
}
.med-btn {
  border: none;
}
.phar-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.phar-title {
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
}
.phar-top-btn {
  margin-right: 20px;
}
.add-fav-phar .modal-content {
  width: 700px !important;
}
.phar-modal-btn {
  display: flex;
  align-items: center;
}
.phar-modal-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.pro-title {
  /* padding: 10px 12px ; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pro-body-item {
  margin: 12px 0px 0px 12px;
}
.pro-top-inp {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.phar-modal-middle {
  overflow: auto;
}
.pres-types {
  display: flex;
  align-items: center;
}
.pro-btn {
  background-color: #2066c2;
  color: white;
}
.rx_profile_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin: 0px 20px 0px 24px !important;
}
.rx-pro-bd {
  height: 170px;
  overflow: auto;
}
.profile-btn-container {
  border: 1px solid lightgray;
  width: 20%;
  border-radius: 4px;
  margin-top: 20px;
}
.favorite_prescription_add .ant-row {
  margin: 14px 0px;
}
.favorite_prescription_add .ant-form-item-control-label,
.pres-label,
.pres-label1 {
  font-size: 14px;
  font-weight: 500;
  color: #2e2c34;
}
.pres-label {
  margin-bottom: 6px;
}
.lab-title {
  color: #2e2c34;
  font-weight: 500;
  font-size: 28px;
}
.set-lab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
}
.bank_detail_card {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.card-shadow {
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
}
.id_verify_title {
  color: #2d3748;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.id_verify_sub_text {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #747474;
  margin-top: 8px;
}
.validate_id {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
  padding: 8px;
}
.validate_id .list-group-item {
  border: none !important;
  padding: 3px 15px;
  color: #718096;
  font-size: 14px;
  line-height: 17px;
}
.circle_icon_ {
  font-size: 9px;
  margin-right: 15px;
}
.radio_id {
  display: flex;
}
.radio_id_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.46px;
  color: #2d3748;
  margin-bottom: 12px;
}
.bank_check_1 .ant-checkbox-wrapper {
  margin: 0px 10px 0px 0px !important;
}
.id_upload {
  width: 400px;
  border: 1px solid #cbd5e0;
  border-style: dashed;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  margin-left: 0px;
}
.AccVerifiy .modal-dialog {
  width: 385px;
  height: 190px;
  top: 175px !important;
}
.AccVerifiy .modal-body {
  /* height: 100px; */
  margin: 10px 20px;
}
.AccVerifiy .modal-footer {
  padding: 10px !important;
}
.foot_btn_setting {
  width: 100%;
  text-align: end;
  margin-right: 25px;
}
.bill_title {
  text-align: left;
  color: #245c66;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  margin-left: 20px;
}
.fav-pres-ant .ant-form-item-control {
  width: 100%;
}
.edu_upload_hint_billing {
  color: #718096;
  font-size: 14px;
  line-height: 17px;
}
.Uploade_browse_btn {
  color: #245c66 !important;
  z-index: 1000;
}
.set-doc-logo-text {
  text-align: left;
  margin-left: 20px;
  padding-top: 5px;
  font-size: 16px;
}

/* ----------------------By Jeyabalaji ---------------------------- */
.for-erx-set-head {
  padding-left: 24px !important;
  padding-right: 20px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.for-fav-pres-box {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.erx-set-for-tab-bot .ant-tabs-nav-wrap {
  margin-left: 3px !important;
}
.fav-phar-top {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.erx-set-for-tab-bot .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0px !important;
}
.fav-pres-table .MuiTableContainer-root {
  margin-top: 0px !important;
}
.app-rem-set-top-lay {
  padding-bottom: 24px !important;
  padding-left: 20px !important;
  padding-top: 24px !important;
  padding-right: 24px !important;
}
.cal-set-form-grp .form-group {
  margin-bottom: 0px !important;
  padding-bottom: 16px !important;
}
.cal-set-form-grp-max-wid .col {
  max-width: 33.33% !important;
}
.cal-set-but-pad {
  margin-top: 4px !important;
}
.pay-srch-box-rad .ant-input-wrapper {
  background-color: #fcfcfc !important;
}
/* .pay-srch-head-pad .payer_layer {
  padding-bottom: 24px !important;
} */
.pay-srch-box-for-srch {
  padding-bottom: 18px !important;
  margin-bottom: 24px !important;
}

.rx-prof-set-box-sty {
  width: 30%;
  margin-top: 0px !important;
  margin-right: 20px !important;
}
.for-erx-phar {
  margin-left: 24px !important;
}
.for-rx-prof-head-box {
  display: flex;
  margin-bottom: 4px !important;
  padding-top: 12px !important;
  padding-left: 24px !important;
  height: 60px;
  justify-content: space-between;
}
.for-rx-pro-title {
  font-size: 20px;
  font-weight: 600;
  width: 200px;
  text-align: left;
}
.lab-tab-pad .MuiTableContainer-root {
  margin-top: 0px !important;
}
/* ----------------------By Jeyabalaji ---------------------------- */
/* updating by cheran 1/2/22  start--------------------------------------------------------------*/
.right-block .proba-top-layer {
  display: flex;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 14px;
  align-items: center;
}
.right-block .probs-top-layer {
  display: flex;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 15px;
  padding-bottom: 14px;
  align-items: center;
}

.right-block .prob_a-top-layer {
  display: flex;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 11px;
  padding-bottom: 8px;

  align-items: center;
}
.right-block .prob__a-top-layer {
  display: flex;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 19px;
  padding-bottom: 8px;

  align-items: center;
}
.right-block #proba-head-right {
  padding-top: 15px;
}
.right-block #prob_a-head-title {
  padding-top: 2px;
  padding-bottom: 14px;
  margin-top: 2px;
}
.right-block #CL {
  padding-top: 6px;
}
.right-block #proba-head-title {
  padding-top: 0px;
  margin-top: 2px;
}
.right-block #prob_a-head-right {
  padding-top: 0px;
  /* margin-top: 2px; */
}
.right-block .prob-a-top-layer {
  display: flex;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 6px;
  align-items: center;
}

.right-block .cbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-block #Cbtn:hover {
  background-color: #007bff;
  color: white;
}
.right-block #Ccheckstrpexp1 {
  padding-left: 4px;
}
.right-block #ch {
  padding-left: 24px;
  margin-bottom: -10px;
}
.right-block #ich {
  margin-left: 71px;
}
.right-block #ich1 {
  margin-left: 22px;
}
.right-block .Cborder {
  border-bottom: 1px solid #cbd5e0 !important;
}
.marg-btn-top {
  margin-top: 2px !important;
}
.for-padb-nil {
  padding-bottom: 0px !important;
}
.right-block #cptl {
  padding-top: 29px;
}
.right-block #cpt2 {
  padding-top: 29px;
}
.right-block .Cborder {
  border-bottom: 1px solid #cbd5e0 !important;
}

/* updating by cheran 1/2/22  end*/

/* update by Christina 11/02/2022 starts here */

/*Subscription-Block CSS starts here  */

.subscription-container-1 {
  text-align: left;
  margin-left: -6px;
  margin-right: 0px;
  margin-top: 8px;
  max-width: 762px;
  width: 762px;
}
.subscription-container1-heading {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 5px;
}
.subscription-container1-heading span {
  color: #245c66;
}
.subscription-container1-column2 {
  padding-right: 0px !important;
}
.subscription-container1-button {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  background: #245c66;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-left: 50px;
}
.subscription-container2 {
  text-align: left;
  margin-left: 4px;
  margin-right: 0px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 762px;
  width: 762px;
  padding-right: 0px !important;
}
.subscription-container2-icon {
  margin-left: 10px;
  padding-top: 17px;
}
.subscription-container2-heading {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin-left: 0px;
  display: inline-block;
  padding-top: 6px !important;
}
.subscription-container2-badge {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #20c9ac;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 24px;
  margin-right: 10px;
  background: rgba(32, 201, 172, 0.1);
  border-radius: 4px;
  padding: 2px 8px 4px !important;
  display: inline-block;
  margin-top: 2px;
}
.subscription-container2-para {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #718096;
  margin-top: 0px;
  padding-bottom: 18px;
}
.subscription-container2-column {
  margin-left: -16px;
  margin-top: 5px;
}
.subscription-container2-para2 {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #245c66;
  padding-top: 12px;
}
.subscription-heading {
  text-align: left;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-left: 4px;
  margin-top: 21px;
  margin-bottom: 16px;
}
.subscription-listgroup {
  width: 762px;
  text-align: left;
  margin-left: 4px;
}
.subscription-list-heading {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.subscription-list-para {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}
.subscription-list-badge {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #84818a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
  background: rgba(182, 180, 186, 0.1);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 4px;
}
.subscription-list {
  height: 49px;
  padding-left: 2px;
  padding-top: 3px;
  margin-bottom: -10px;
}
.subscription-compare-row-column {
  padding-right: 0px !important;
  padding-bottom: 16px;
}
.subscription-compare-button {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0015em;
  color: #2662f0;
  border: 1px solid #245c66;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  margin-left: 59px;
}
.subscription-compare-row {
  max-width: 762px;
  width: 762px;
  margin-top: 24px;
}
.subscription-div {
  padding-bottom: 9px;
}
/*Subscription-Block CSS ends here  */

/* update by Christina 11/02/2022 starts here */
/* jeyabalaji */
.get-started-billser-pop .modal-dialog {
  margin-top: 6.3%;
  float: left;
  margin-left: 28%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-saveinforem-pop .modal-dialog {
  margin-top: 26%;
  float: left;
  margin-left: 31%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-editinfoprofile-pop .modal-dialog {
  margin-top: 11.5%;
  float: left;
  margin-left: 33%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-clinicalsavereminder-pop .modal-dialog {
  margin-top: 27.5%;
  float: left;
  margin-left: 29%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-forprofilepassword-pop .modal-dialog {
  margin-top: 23.5%;
  float: left;
  margin-left: 33%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-accinfoprofile-pop .modal-dialog {
  margin-top: 29%;
  float: left;
  margin-left: 33%;
  height: 208px !important;
  width: 415px !important;
}
.explore-billing-service .modal-content {
  width: 400px !important;
  /* height: 374px !important; */
}
.explore-billing-service .modal-body {
  padding: 0px !important;
}

.explore-billing-service .modal-dialog {
  /* float: left;
  margin-top: 15% !important;
  margin-left: 20px !important; */
  width: 400px !important;
  position: fixed;
  top: auto;
  right: auto !important;
  /* left: auto; */
  bottom: 0;
  margin-left: 30px !important;
}
.explore-profilepopup-service .modal-content {
  width: 400px !important;
  height: 374px !important;
}
.explore-profilepopup-service .modal-body {
  padding: 0px !important;
}

.explore-profilepopup-service .modal-dialog {
  /* float: left;
  margin-top: 15% !important;
  margin-left: 20px !important; */
  width: 400px !important;
  position: fixed;
  top: auto;
  right: auto !important;
  /* left: auto; */
  bottom: 0;
  margin-left: 30px !important;
}
.explore-facilitystaffpopup-service .modal-content {
  width: 400px !important;
  /* height: 374px !important; */
}
.explore-facilitystaffpopup-service .modal-body {
  padding: 0px !important;
}

.explore-facilitystaffpopup-service .modal-dialog {
  /* float: left;
  margin-top: 15% !important;
  margin-left: 20px !important; */
  width: 400px !important;
  position: fixed;
  top: auto;
  right: auto !important;
  /* left: auto; */
  bottom: 0;
  margin-left: 30px !important;
}
.get-started-billset-pop .modal-dialog {
  margin-top: 6.1%;
  float: left;
  margin-left: 28%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-addfacility-pop .modal-dialog {
  margin-top: 4%;
  /* float: left; */
  margin-right: 15%;
  height: 208px !important;
  width: 415px !important;
  z-index: 900 !important;
}
.get-started-usermenu-pop .modal-dialog {
  margin-top: 13.8%;
  float: left;
  margin-left: 10%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-rolesinfo-pop .modal-dialog {
  margin-top: 16.4%;
  float: left;
  margin-left: 16%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-clinicinfo-pop .modal-dialog {
  margin-top: 28.5%;
  float: left;
  margin-left: 33%;
  height: 208px !important;
  width: 415px !important;
}

#click_event_add_facility {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 60px;
  margin-left: -20px;
}
#click_event_user_menu {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 170px;
  margin-left: -100px;
}
#click_event_provider_add {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 55px;
  margin-left: 0px;
}
#click_event_provider_staff {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 175px;
  margin-left: -150px;
}
#click_event_roles_permission {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 215px;
  margin-left: -60px;
}
#click_event_add_services {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 30px;
  margin-left: 120px;
}
#click_event_prof-edit-info {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: -10px;
  margin-left: 220px;
}
#click_event_acc-info {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 35px;
  margin-left: 180px;
}
#click_event_prof-change-pass {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 55px;
  margin-left: 180px;
}
#click_event_prof-change-pass {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 55px;
  margin-left: 180px;
}
#click_event_prof-clinician-info {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 55px;
  margin-left: 180px;
}
#click_event_prof-clinician-save-rem {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: -40px;
  margin-left: 60px;
}

#click_event_billing_setting {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 10px;
  margin-left: 210px;
}
#click_event_save_remin_bill_set {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: -50px;
  margin-left: 60px;
}

.for-bill-set-save-rem .ui.right.center.popup:before {
  top: 55% !important;
}

.for-get-started-popup-for-res-3 {
  padding-left: 6px !important;
  padding-right: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 14px !important;
}

.for-get-started-popup-for-res-4 {
  padding-left: 6px !important;
  padding-right: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 12px !important;
}

/* jeyabalaji */
/* ------------------------------Vanitha -16-02-22---------------------- */
.payer_search {
  height: 32px !important;
}

.erx_third_row {
  background-color: #f7f7f7;
  padding-top: 10px;
  padding-bottom: 10px;
}
.modif1 {
  padding: 0px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: DM Sans, sans-serif;
}
.modif1_left {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #245c66;
  float: left !important;
  margin-left: 20px;
}
.fav_pres_bot {
  margin-bottom: 22px !important;
}
.erx_prof {
  margin-top: 8px !important;
}

.right-block .appt-block-main .payer_search input {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  line-height: 16px;
  padding-top: 3px !important;
}

.appt-block-main .payer_search button {
  display: grid;
  place-content: center;
}

/* jeybalaji */
.for-left-nav-dropdown .btn-link:hover {
  text-decoration: none !important;
}
.for-left-nav-dropdown .btn:focus {
  box-shadow: none !important;
  text-decoration: none !important;
}
.for-left-nav-dropdown .accordion {
  width: 240px !important;
}
.arrow-rotate-sett-nav {
  transform: rotate(-180deg);
}
.for-set-nav-arrow1 svg {
  margin-left: 35px !important;
}
.for-set-nav-arrow2 svg {
  margin-left: 95px !important;
}
.for-set-nav-arrow3 svg {
  margin-left: 16px !important;
}
.for-set-nav-arrow4 svg {
  margin-left: 76px !important;
}
.for-set-nav-arrow5 svg {
  margin-left: 48px !important;
}
/* jeyabalaji */
.us-box-m-t-20 {
  margin-top: 20px !important;
}

/* Vanitha 18-04-22 Start */
.subs_cont2_ico_alt {
  margin-left: 12px !important;
  padding-top: 18px !important;
}
.sub_cont2_badge_alt {
  margin-top: 14px !important;
  margin-left: 13px !important;
}

.subs_cont2_para_alt {
  margin-top: 12px;
  padding-bottom: 16px;
  margin-left: 25px;
}

.subs_upg_btn_alt {
  margin-left: 64px !important;
}
.subs_plancmp_btn_alt {
  margin-right: -37px !important;
}
.subscription-list-para_alt {
  margin-top: 5px;
}
.subs_cont2_heading_alt {
  margin-left: 25px !important;
}

/* Vanitha 18-04-22 Start */
.myProfileSetting {
  margin-top: 6px;
  margin-bottom: 10px;
  margin-left: 2px;
  height: 34px !important;
}
.myProfileSetting2 {
  margin-top: 6px;
  margin-bottom: 10px;
  margin-left: -3px;
  height: 34px !important;
  padding-top: 2px;
}

.tab_title_main .facilityBotBorder {
  margin-bottom: 11px !important;
}
.for-marg-facility .ant-input {
  height: 34px !important;
}
.for-marg-facility .ui.icon.input > input {
  background-color: #ffff !important;
  height: 34px !important;
}
.for-marg-facility {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  border-radius: 6px !important;
  overflow: hidden;
}

.ant_rad_in_alt .ant-radio-inner {
  top: 1px !important;
}

.us-box-m-t-20 {
  margin-top: 20px !important;
}
.erx-add-fav-btn {
  margin: 0.5px 6px 2px -6px;
}
.add-fav-pres-btn {
  height: 34px;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 4px;
}
.erx-fp-ml {
  margin-left: 38%;
}
.fav-ph-ad-btn {
  margin: 0px 6px 2px 0px;
}
.add-fav-phar-line {
  width: 108.5%;
  margin-left: -20px;
}
.ad-rx-pr-btn {
  text-decoration: none;
  font-weight: 500;
  padding-top: 3px;
}
.rx-pdt-26 {
  padding-bottom: 26px;
}
.rx-pre-err-mar {
  margin-left: 115%;
  width: 100%;
}
.rx-pr-mr-1 {
  margin: 25px 0px 8px 15px;
}
.rx-pr-mtb-2 {
  padding: 15px 0px 15px 0px;
}
.rx-pr-mr-2 {
  margin-left: 70px;
}
.rx-pr-mt-zd {
  margin-top: 0px !important;
}
/*----------------update by cheran 5-5-22 Start---------------*/
.lm-stg-btn {
  padding: 20px !important;
}
#demographics > div > div > div.m-l-20-r-25.services-list1 > :last-child {
  border-bottom: 1px solid #cbd5e0 !important ;
}
.stng-ser-block {
  padding: 22px 20px 21px 20px !important;
}
.stng-h2-mg {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.adpy-row-adj1 .form-group .form-control {
  margin-right: 20px !important;
}
/* .in_f_row .form-group .form-control */
.adpy-row-adj .form-group .form-control {
  margin-right: 20px !important;
}
.stng-flex-with-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 20px !important;
}
.c-title-weight-adj {
  font-weight: 600 !important;
}
/*----------------update by cheran 5-5-22 End---------------*/
.settingsAvatar .ant-avatar img {
  height: 83% !important;
  width: 94% !important;
}
.settingPersonalAvatar {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 18px;
  text-align: center;
}
.settingLabFont {
  font-weight: 600 !important;
}
.settingLabHead tr {
  border-top: none !important;
}
.addSettingLabLable {
  margin-bottom: 0px !important;
}
.facilitySubTitle {
  font-weight: 600 !important;
}
.calenderSettingErrMsg {
  margin-left: -16px !important;
}
.settingPersonalAvat .ant-avatar-string {
  width: 73px;
  margin-top: 13px;
}
.settingProfileUpload {
  margin: 0px 20px 0px 20px;
}

.setttingProfilePictureWid .modal-dialog {
  width: 400px !important;
}
.settingProfileFooter {
  margin-left: 1px !important;
  margin-bottom: 6px;
}

.settingProfileFooter Button {
  margin-top: 9px;
}
/* Vanitha 06-05-22 */
.tab_title_name1 {
  font-family: DM Sans, sans-serif !important;
  font-weight: 500 !important;
  font-size: 28px !important;
  line-height: 33px !important;
  display: flex;
  align-items: center !important;
  color: #2e2c34 !important;
}

.font_change_ {
  font-size: 18px !important;
}

/* Vanitha 06-05-22 */

.addFacilityButton {
  margin-top: 10px;
  margin-right: 10px;
}
.addFacilityButton span {
  margin-left: 6px;
}
.addFacilityButton svg {
  margin-top: -1px;
}
/* Vanitha 06-05-22 */
.bor_secu_alt {
  margin-left: -24px !important;
  margin-right: -20px !important;
}

/* Vanitha 06-05-22 */

/* Vanitha 09-05-22 */

.sec_stg_pswd_alt {
  margin-top: -2px !important;
}

.secu_new_pswd_alt {
  margin-top: -5px !important;
}

.secu_exp_dayBox_alt input {
  margin-top: 1px !important;
}

.secu_save_btn_alt {
  margin-top: 4px !important;
  margin-bottom: 20px !important;
}

.secu_sec_tit_alt {
  margin-bottom: -9px !important;
}

.mypln_tot_pg_alt {
  margin-left: -4px;
  margin-bottom: 1px;
}

.mypln_rwcmpr_alt {
  display: flex;
  margin-top: 16px !important;
  margin-bottom: 0px !important;
}

.subs_upg_btn_bot_alt {
  margin-bottom: 6px !important;
}

.setdoc_invoice_logo {
  margin-top: -10px !important;
}

.setdoc_footr_info {
  margin-top: 4px !important;
}

.sec_doc_marg_top_alt {
  margin-top: 13px !important;
}

.sec_doc_stmt_invlogo {
  margin-top: -11px !important;
}

.sec_doc_stmt_foot {
  margin-top: 5px !important;
}

.sec_doc_supbill_invlogo {
  margin-top: -11px !important;
}

.sec_doc_supbill_secthi_invlogo {
  margin-top: -12px !important;
}

.sec_doc_supbill_foot_alt {
  margin-top: 4px !important;
}

.secu_doc_doc_invlogo_alt {
  margin-top: -13px !important;
}

.secu_doc_doc_footr_alt {
  margin-top: 4px !important;
}

.secu_doc_savedoc_btn_alt {
  margin-top: -4px !important;
  margin-bottom: -5px !important;
}

.sec_new_pswd_alt {
  margin-top: -5px !important;
}

.mypln_tot_pg_alt .list-group {
  border-radius: 10px !important;
}

/* Vanitha 09-05-22 */
.facilitySettingTitle {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
}
.facilityBilllingModalBody .form-row {
  margin-left: -8px;
  margin-right: -8px;
}
.settingProfileUploadPicTitle {
  margin-top: 6px;
  margin-bottom: 3px;
}

.addNewLabSvg svg {
  padding-right: 3px;
  margin-top: -3px;
}
.addNewLabSvg span {
  margin-left: 5px;
}
.settingClinicalSmallTitle {
  margin-top: 16px !important;
}
.settingClinicalButton {
  padding-top: 4px;
  margin-top: 11px;
  margin-bottom: 2px;
  height: 34px;
}
.settingClinicalPara {
  margin-top: 2px;
  margin-bottom: 16px;
}
.ct-rx-md-foot.modal-footer button {
  margin: 0px 20px 0px 0px !important;
}
.table-tp-lyr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-tabl-main-txt {
  color: black !important;
  font-weight: 500 !important;
}
.ad-us-li-btn {
  width: 130px;
  display: flex;
  align-items: center;
}
.ad-li-btn-icon {
  margin-left: -5px;
  margin-right: 5px;
}
.btn-primary {
  /* background: #245C66  !important; */
  /* border-color: #245C66 !important; */
}
.btn-primary:hover {
  /* background: #16424A !important; */
  /* border-color: #16424A !important; */
}

.addlic-btn {
  color: #245c66 !important;
  background: none !important;
  border-color: #245c66 !important;
}
.addlic-btn:hover {
  color: #ffffff !important;
  background: #245c66 !important;
  border-color: #245c66 !important;
}

.us-bd-pd-0.modal-body {
  padding: 20px 20px 0px 20px !important;
}
.adus-fac-mgbot.form-group {
  margin-bottom: 0px !important;
}
.ads-tit-margt.modal-title {
  margin-top: 0px !important;
}
.ads-modh-margt.modal-header,
.ads-modh-margt.modal-footer {
  padding: 20px !important;
}
.adus-licst-inp.form-control {
  width: 140px;
}
.all-flx-al-cent {
  display: flex;
  align-items: center;
}
.give-mrg-lf-10 .form-group {
  margin-right: 10px !important;
}
.adus-del-icon {
  color: red;
  height: 20px;
  width: 20px;
  margin-top: 34px;
}
.adus-btn-pop-marg svg {
  margin-left: -5px;
  margin-right: 5px;
  margin-top: -2px;
}
.fav-pr-ed-ic {
  width: 24px;
  cursor: poDM Sans;
}
.fav-prs-mod-sltr .modal-dialog {
  min-width: 546px !important;
}
.fav-prs-mod-sltr .modal-content {
  width: 546px !important;
}

/*Ahamed_Changes_12-05-22*/
.set-bill-title {
  font-family: DM Sans, sans-serif;
  font-weight: 500;
  font-size: 28px !important;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #2e2c34;
}
.bill_sett .prob-top-layer {
  padding-top: 20px !important;
}
/*Ahamed_Changes_12-05-22*/
.table-for-groups {
  border: 1px solid rgb(226, 232, 237);
  margin: 20px;
  border-radius: 4px;
}
/* Vanitha 11-05-22 */

.secu_sec_tit_alt1 {
  margin-bottom: -2px !important;
  margin-top: 16px !important;
}
/* Vanitha 11-05-22 */

/* Vanitha 12-05-22 */

.secu_doc_alt_for_each_subtit {
  font-size: 18px !important;
  color: #245c66 !important;
  font-weight: 600 !important;
  margin-left: 19px !important;
  font-family: DM Sans, sans-serif !important;
}

.aft_marg_alt_ {
  margin-top: -31px !important;
  margin-bottom: 20px !important;
}

.aft_marg_alt_1 {
  margin-top: -13px !important;
}

.sett_docume_marg_alt {
  margin-top: -19px !important;
  margin-bottom: 5px !important;
}
/* Vanitha 12-05-22 */

/* Vanitha 15-05-22 */
.secu_doc_foot_top_Alt {
  margin-top: 8px !important;
}

/* Vanitha 15-05-22 */

/* Vanitha 15-05-22 */

.subs_cont2_para_alt1 {
  margin-top: 8px !important;
}

.myplan_firstpara_alt {
  padding-bottom: 0px !important;
}

.appt_remd_modpopup_alt .modal-dialog {
  width: 500px !important;
}

/* Vanitha 15-05-22 */

.settingProfileUploadPicHeader .close {
  margin-top: 7px;
}
/*-----------------update by Cheran Start -----------------------*/
.SSB-MD .modal-dialog {
  height: max-content !important;
}
.sttng-final-lm {
  padding-bottom: 10px !important;
}
.del-mg-stng {
  margin-left: 240px !important;
}
.sttng-final-lm1 {
  padding-bottom: 0px !important;
}
.adpy-row-adj-final2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.adpy-row-adj-final {
  margin-top: 2px !important;
}
.adpy-row-adj-final .form-group .form-control {
  margin-right: 0px !important;
}
.adpy-row-adj-final2 .form-group .form-control {
  margin-right: 0px !important;
}
.SSB-buton-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 5px 0px;
}
.rp-head-title
  > div.MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1.MuiPaper-rounded {
  margin-top: 0px !important;
}
.head-mg-sttng {
  margin-top: 0px !important;
}
#demographics
  > div
  > div
  > div.payer_sec_row_main
  > .ant-input-search
  .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #d9d9d9 !important ;
}
#demographics > div > div > div.payer_sec_row_main .ant-btn:hover,
.ant-btn:focus {
  border-color: #d9d9d9 !important ;
}
/*-----------------update by Cheran End -----------------------*/
.Time_Zone {
  text-align: left !important;
}
.Time_Zone .ant-checkbox-wrapper {
  margin-top: 5px !important;
}
/*-----------------update by Cheran Start -----------------------*/
.chk-wid {
  max-width: 100px !important;

  margin-bottom: 12px !important;
  height: 34px;
}
.chk-wid .ant-checkbox-wrapper {
  margin-top: 12px !important;
}
.chk-lft {
  max-width: 250px !important;
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}
.stng-bill-stng .modal-dialog {
  top: 68px !important;
}
.chk-row-top {
  padding-bottom: 0px !important;
}

/*-----------------update by Cheran End -----------------------*/

/* Vanitha 15-06-22 */
.whole_tab div {
  /* border-radius: 6px !important;
  overflow-x: hidden !important; */
}
/* Vanitha 15-06-22 */
/*Ahamed_Changes 20-06-2022*/
#billsetting button.create-appt {
  margin-bottom: 4px !important;
}
#billsetting .vrfy-txt {
  color: #718096;
  margin-bottom: 16px;
  font-size: 12px;
}
/*Ahamed_Changes 20-06-2022*/
/*Ahamed_Changes 22-06-2022*/
/* .pat-portal .schedule-comp1 {
  max-width: 1092px;
} */

.ant-checkbox-wrapper-disabled .ant-checkbox-inner::after {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  /* background-color: #b1e9ff !important; */
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  border-collapse: separate;
  transition: all 0.3s;
  top: 50% !important;
  left: 50% !important;
  width: 8px !important;
  height: 8px !important;
  border: 1px solid #d9d9d9 !important;
  border: 0 !important;
  transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1 !important;
  content: " " !important;
}

.selectone {
  width: 55%;
  display: flex;
  flex-direction: column;
}

/*Ahamed_Start 29-09-2022*/
#domain_change.modal-dialog {
  width: 584px;
  max-width: 584px;
}
/*Ahamed_End 29-09-2022*/
/* Vanitha 25-09-22 */
.settingdocdragdrop input[type="file"] {
  width: 550px !important;
  height: 235px !important;
  margin: 6px 7px 3px 11px !important;
  padding: 208px 80px 69px 116px !important;
}

.setmyprof_dragdrop input[type="file"] {
  width: 351px !important;
  height: 76px !important;
  margin: 6px 7px 3px 11px !important;
  padding: 122px 80px 69px 116px !important;
}

/* Vanitha 25-09-22 */

/* Ramachandhiran Document Logo Start  */
.Upload_conatinner {
  display: flex;
  margin: 16px 24px;
}
.default_image_div {
  height: 80px;
  width: 80px;
  border-radius: 50% !important;
  background-color: #f0f0f0 !important;
  margin-right: 22px;
}
.DImage {
  margin: 15px;
  filter: invert(32%) sepia(67%) saturate(7487%) hue-rotate(167deg)
    brightness(90%) contrast(91%);
}
.DImage12 {
  border-radius: 50%;
  margin-right: 22px;
}
.UploadDocument {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload_Btn {
  font-family: "DM Sans" sans-serif;
  font-weight: 500;
  margin: 0px 16px 0px 22px;
}
.upload_Btn_primary {
  font-family: "DM Sans" sans-serif;
  font-weight: 500;
  margin: 0px 23px 0px -2px;
}
.delete_Btn {
  background-color: #e7e7e9;
  color: #000000;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  border: none;
}
.flexBoxColumn {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.labelBox {
  font-family: "DM Sans", sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a3a3a3;
  margin-top: 10px;
}
.saveCancel {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  margin-left: 100px;
}

.delete_alert_dialog {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.06), 0px 16px 16px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 16px 20px;
}
.alert_title {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  line-height: 16px;
  color: #2e2c34;
}
.alert_body {
  font-family: "DM Sans", sans-serif;
  line-height: 16px;
  color: #6a6a6a;
  margin-bottom: 15px;
}

.inputFile::file-selector-button {
  background: #e6e6e6;
  border: 1px solid #c1c8d2;
  border-radius: 2px;
  outline: none;
  padding: 6px 8px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0d0c22;
  cursor: poDM Sans;
}

/* Ramachandhiran Document Logo End  */

.SecureData_alt .arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #69737c;
  position: absolute;
  top: 79.1%;
  margin-left: 20px;
}

.clipboard_copiedbtn {
  border-radius: 6px;
  background-color: #69737c;
  padding: 8px;
  color: #ffffff;
  position: absolute;
  top: 88%;
  font-family: DM Sans, sans-serif;
}
.hg_checkbox .ant-checkbox-wrapper {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* .hg_active .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
} */

/* Vanitha 21-11-22 */

.saveinfopadissue {
  padding-top: 4px !important;
}

/* Vanitha 21-11-22 */
.stg-paddless .appt-block-main {
  padding-bottom: 20px !important;
}
.stn-pad-bottom-exces-red {
  padding-bottom: 0px !important;
}
.padd-top-stng-red {
  padding-top: 0px !important;
}
.file_errormessage {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
  text-align: left;
  font-weight: 600;
  font-family: "DM Sans, sans-serif";
}

/* Vanitha 10-02-23 */

.flexBoxColumn input[type="file"] {
  width: 100px;
  color: transparent;
}

/* Vanitha 10-02-23 */
.erx-set-for-tab-bot .ant-tabs-tab {
  padding: 0px 0px 12px 0px !important;
}

/* nisha 14-02-23 */
.style-for-flex {
  display: flex;
}
.per-pro-img {
  border-radius: 50%;
  width: 80px !important;
  height: 80px !important;
}
.patname-per {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #a8aebe;
}
.de-sty-hwb {
  height: 24px !important;
  width: 24px !important;
  border: none !important;
  top: 9px !important;
}
.t-left {
  text-align: left;
}
.sty-m-5-d-n {
  margin-bottom: 5px !important;
  display: none;
}
.sty-m-t-4 {
  margin-top: 4px;
}
.j-c-spacebet {
  justify-content: space-between;
}
.m-b-4 {
  margin-bottom: 4px;
}
.p-b-1 {
  padding-bottom: 1px;
}
.m-l-100 .m-t-40 {
  margin-top: -100px;
  margin-top: 40px;
}
.app-pro-popup {
  margin-top: 235px !important;
  margin-right: 146px !important;
  z-index: 0px !important;
}
.m-r--17 {
  margin-right: -17px;
}
.m-b--9 {
  margin-bottom: -9px;
}
.m-b--10 {
  margin-bottom: -10px;
}
.cur-poDM Sans {
  cursor: poDM Sans;
}
.m-t-2 {
  margin-top: 2px !important;
}
.sty-mr-5 {
  margin: 5px;
}
/* css atrt 14:02:23 */
.m-l--20 {
  margin-left: -20px;
}
.m-t-2 {
  margin-top: 2px;
}
.m-l--20.width {
  margin-left: -20px;
  width: 50%;
}
.m-t-0-imp {
  margin-top: 0px !important;
}
.p-t-18 {
  padding-top: 18px;
}
.sty-mar {
  margin: -20px 0px 8px -27px;
}
.sty-m-t-22 {
  margin-top: -22px;
}
.col-2e-f-w-500 {
  color: #2e2e2e !important;
  font-weight: 500 !important;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t--12-p-l-3 {
  margin-top: -12px;
  padding-left: 3px;
}
.display-block {
  display: block;
}
.m-t-248-m-r-156 {
  margin-top: 248px;
  margin-right: 156px;
}
.p-r-5 {
  padding-right: 5px;
}
.m-t-3 {
  margin-top: 3px !important;
}
.mr-b-16 {
  margin-bottom: 16px !important;
}
.sty-w-168 {
  width: 168px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-t-3 {
  padding-top: 3px;
}
.p-r-0 {
  padding-right: 0px !important;
}
/* .m-t-5.m-l-20 {
  margin-top: 5.5px !important;
  margin-left: 20px !important;
} */
.p-l-0 {
  padding-left: 0px;
}
.f-w-w {
  flex-wrap: wrap;
}
.sty-b-c {
  background-color: #f7f7f7;
}
.sty-h-32 {
  height: 32px;
}
.m-t--10 {
  margin-top: -10px;
}
.sty-c-2e2e2e {
  color: #2e2e2e !important;
}
.sty-c-2c7be5 {
  color: #245c66 !important;
}
.sty-m-t-24 {
  margin-top: 24.5px;
}
.sty-w-250 {
  /* width: 250px !important; */
  width: 100% !important;
}
.m-t--7 {
  margin-top: -7px;
}
.m-l-4 {
  margin-left: 4px;
}
.m-r-17 {
  margin-right: 17px;
}
.mypro-cli-del-btn {
  color: red;
  height: 20px;
  width: 20px;
  cursor: poDM Sans !important;
}

.m-t--12 {
  margin-top: -12px;
}
.p-l-3 {
  padding-left: 3px;
}
.m-5 {
  margin: 5px !important;
}
.m-t--19 {
  margin-top: -19px !important;
}
.c-red {
  color: red !important;
}

/* nisha end 17-02-23 */

/* srayanya inline css start */
.emailtextbutton {
  text-decoration: none !important;
}

.messcontentpre {
  white-space: pre-wrap !important;
}

.portalsignbutton {
  background-color: #245c66;
  /* radius:4px; */
  height: 32px;
  color: #ffffff;
  padding: 8px 33px;
  font-size: 14px;
  border: transparent;
  /* weight:500; */
  line-height: 17px;
  font-weight: 500;
  border-radius: 4px;
}

.modclobtn {
  padding-left: 5 !important;
  z-index: 1001;
}

.erxenable {
  margin-left: 0px;
  font-weight: 400;
  font-size: 14px;
  font-family: DM Sans, sans-serif;
  color: #718096;
  line-height: 16.94px;
}

.erxwholediv {
  padding: 24px 20px 20px 20px;
}

.erxencrdiv {
  align-items: center;
  padding-bottom: 15px;
  margin-top: -3px;
}
.erxencrmar {
  margin-right: 80px;
}
.erxpwshow {
  position: absolute;
  left: 770;
  top: 249px;
  cursor: poDM Sans;
  font-size: 16;
  color: #7d8592;
}

.ich {
  width: 290px;
  margin-left: 99px !important;
  padding-right: 30px;
}
.ich2 {
  width: 290px;
  margin-left: 115px !important;
}
.erxpassshow {
  position: absolute;
  left: 602px;
  /* top: 249px; */
  cursor: poDM Sans;
  color: #7d8592;
  font-size: 16px;
}
.erxpassshow1 {
  position: absolute;
  left: 262px;
  top: 10px;
  cursor: poDM Sans;
  color: #7d8592;
  font-size: 16px;
}

.erxerr {
  margin-top: 4px;
  margin-left: 242px;
  text-align: left;
}
.erxich1 {
  width: 290px;
  margin-left: 0px !important;
  padding-right: 30px;
}
.erx_pass_inp_div {
  position: relative;
  margin-left: 16px;
}
.erx_acc_div {
  align-items: center;
  padding-bottom: 0px;
}
.erx_cli_div {
  align-items: center;
  padding-bottom: 15px;
}

.erx_btn_div {
  text-align: left;
  padding: 20px 20px 10px 20px;
}
.erx_btn_cancel {
  color: #245c66;
  border: 1px solid #245c66;
  background-color: #fff;
  margin-right: 20px;
  padding: 4.5px 10px;
}
.erx_btn_save {
  height: none;
  padding: 5.5px 10px;
}
.erx_modal_icon {
  margin-bottom: 4px !important;
}

.erx_keyerr {
  margin-top: -4px;
  margin-left: 240px;
  text-align: left;
}

.phar_whole_div {
  width: 100%;
  margin-top: 20px;
}

.all-tabl-main-txt div {
  margin-left: 8px;
}

.phar_allign {
  text-align: left !important;
}
.phar_img_div {
  text-align: center !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.phar_allign div {
  margin-left: 8px;
}

.phar_tablehead {
  margin-left: 4px !important;
}
.phar_location {
  width: 280px;
}

.pre_tab_div {
  margin-top: 20px !important;
  margin-bottom: 11px !important;
}

.pre_btn_div {
  margin-right: 20px;
}
.pre_btn_div button {
  font-weight: 500;
  box-shadow: none;
}
.pre_dis_div {
  width: 125px;
}

.pre_btn {
  padding: 10px;
  text-align: center;
  border-radius: 4px !important;
  font-size: 12px;
}

.pre_sub_btn {
  text-align: center;
  border-radius: 4px !important;
  font-size: 12px;
}

.pro_dis {
  margin-left: 32px;
}

.rx_add_btn {
  margin: 0px 6px 2px 0px;
}

.phar_del_btn {
  color: red;
  height: 15px;
  width: 28px;
  cursor: poDM Sans;
}

.cli_rep_table {
  margin-left: 20px !important;
  margin-top: 0px !important;
  margin-right: 20px;
  border: 1px solid #e2e8ed !important;
  max-width: 108vw;
  border-radius: 4px !important;
  overflow-x: auto;
}
@media only screen and (min-width: 1350px) and (max-width: 1400px) {
  .cli_rep_table {
    max-width: calc(100vw - 9.95vw);
  }
}

.cli_pat_table {
  display: flex !important;
  align-items: center;
  min-width: 194px;
}
.cli_img_tab {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.cli_pat_nam {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #a8aebe;
  margin-right: 10px;
}

.cli_unl_spa {
  left: 18% !important;
}

.cli_bil_spa {
  left: 25% !important;
}
.cli_img {
  text-align: center !important;
}

/* saranya inline css end */
/* Ahamed 15-02-23*/
#billsetting .ant-upload-drag-icon > img {
  height: 200px;
}
#billsetting .set-bil_detail_ur {
  margin-right: 24px;
  width: 33.3%;
}
#billsetting .bank_detail_card .card-body:nth-child(1) {
  height: 406px;
}
#billsetting .pop-btn_bil-set {
  margin-left: 120px;
  margin-top: -36px;
}
#billsetting .not_mat_code {
  margin-left: -15px;
}
#billsetting .typ_doc_mrgn {
  margin-top: 18px;
}
#billsetting .in_f_row .m-b-0 {
  margin-bottom: 0px !important;
}
.sett_service .m-l-0 {
  margin-left: 0px;
}
.sett_service .cel_code {
  color: #2e2e2e;
  font-weight: 500;
  cursor: poDM Sans;
}
.mdl-padtpbtm2 .code_typ_st_serv {
  margin-top: 13px;
}
.sett_service .pop_set_serv {
  margin-right: 294px;
  margin-top: 91px;
}
.sett_service .edt_btn_bil_serv {
  width: 67px;
}
.sett_service .p-r-0.fl-mrnil {
  margin-left: -21px;
}
.desc_wid #setting_service_desc {
  width: 268px;
  margin-left: 20px;
}
.sett_service .fee_bil_serv {
  margin-right: 134px;
  margin-left: -21px;
}
.sett_service .fee_bil_serv {
  margin-left: 16px;
  margin-top: 2px;
}
#setting_service_codein .errormessage {
  margin-right: 97%;
  color: red;
  margin-left: -1%;
}
.fee_bil_serv .bil_srvc_code {
  margin-right: 60px;
  /* margin-left: -21px; */
}
.sett_service .red-col {
  margin-left: -29%;
}
.sett_service .desc_wid {
  width: 347px;
}
.sett_service .we-none .errormessage {
  margin-right: 100%;
  color: red;
  margin-left: 0%;
}
.bil_serv_add .vald_fee_bilserv {
  margin-left: 74px;
}
.add-contact-form .errormessage {
  text-align: left;
}
#demographics\ set-bill-set .appt-block-main {
  margin-bottom: 0px !important;
}
.mdl-padtpbtm2 .add-contact-form .errormessage {
  color: #b91414;
}
.add_new_payer .del-mg-stng.red {
  color: #b91414;
  height: 32px !important;
}
/* Ahamed 15-02-23*/

/*vignesh inline css 17-02-23 */

.roles-rightsidecontainer {
  margin-top: 0px !important;
}
.providersTableRole-button {
  position: inherit !important;
}

.permissionManual-rightsidecontainer {
  padding-top: 0;
}

.SecurityBlock-FormControl {
  margin-left: 44px;
}
.SecurityBlock-FormControl1 {
  margin-left: 27px;
}
.SecurityBlock-FormControl2 {
  margin-left: 60px;
  width: 200px;
  height: 32px !important;
}

.SecurityDocummentTable-button {
  text-decoration: none !important;
  margin-top: -8px;
}

.SecurityDocummentTable-container {
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  margin-top: 16px;
}

.SecurityDocummentTable-button2 {
  display: flex;
  justify-content: end;
}

.myplan_index-invoiceTablecell {
  text-align: center !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.myplan_index-container {
  margin-top: -7px !important;
}
.myplan_subscriptionBlock-container {
  width: 65%;
}
.myplan_subscriptionBlock-rightsidecontainer {
  margin-left: 29px;
  margin-right: 10px;
  width: 35% !important;
}
.myplan_subscriptionBlock-button {
  margin-bottom: 4px;
  margin-right: 5px;
}
.myplan_subscriptionBlock-button_color {
  color: #721c24;
}

.invoicepopup-index {
  color: #718096;
  cursor: poDM Sans;
  height: 16px;
  width: 16px;
}
.invoicepopup-index2 {
  color: #718096;
  cursor: poDM Sans;
  height: 24px;
  width: 24px;
  margin-top: 17px;
  margin-right: 24px;
}

.invoicepopup-index3 {
  color: #718096;
  cursor: poDM Sans;
  height: 24px;
  width: 24px;
  margin-top: 17px;
  margin-right: 29px;
}

.calendersettingheight {
  height: 286px !important;
}
/*vignesh inline css 17-02-23 */

/* Jeyabalaji 21-04-2023 */
.pay_srh_tab_p_d .payer_search_rad {
  margin-left: 20px;
}

.pay_srh_tab_p_d .pay_srh_srch_box {
  height: 32px !important;
}
.pay_srh_tab_p_d .pay_srh_new_btn {
  font-weight: 500;
  padding-top: 4px;
}

.pay_srh_tab_p_d .pay_srh_mb_s {
  margin-bottom: 0px;
}
.pay_srh_tab_p_d .pay_srh_ml_s {
  margin-left: -2px;
}
.pay_srh_tab_p_d .pay_srh_tc_c {
  color: #245c66;
}
.pay_srh_tab_p_d .pay_srh_tc_e_i {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.lab_f_hd_d .lab_div_s {
  margin-bottom: 1px !important;
}
.lab_f_hd_d .lab_div_s_mt {
  margin-top: 4px !important;
}
.lab_f_hd_d .lab_block_s_mt {
  margin-top: 0px !important;
}
.lab_f_hd_d .lab_s_mb_10 {
  margin-bottom: 10px !important;
}
.lab_f_hd_d .lab_tc_s_w_mt {
  width: 100%;
  margin-top: 0px !important;
}
.lab_f_hd_d .addNewLabSvg {
  height: 32px;
  margin-right: 20px;
}
.lab_f_hd_d .lab_tc_f_d_td {
  margin-left: 3px;
}
.lab_f_hd_d .lab_tc_d_td_ml_c_fw {
  margin-left: 5px;
  color: #2e2e2e;
  font-weight: 500;
}
.lab_f_hd_d .lab_tc_edit_s {
  color: #245c66;
  cursor: poDM Sans;
  margin-right: 10px;
}
.lab_f_hd_d .lab_tc_emp_img {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.lab_f_hd_d .lab_dis_div_btn {
  right: 160px;
}
/* Jeyabalaji 21-04-2023 */
/* cheran 28-4-23*/
.ch-roles-tabs .ant-tabs-tab {
  padding-top: 0px;
}
.ch-roles-tabs-user .ant-tabs {
  margin-top: -7px;
}
/* cheran 28-4-23*/
/* vignesh 04-05-23 */
.margin_email {
  margin-left: -15px;
}

/* vignesh 04-05-23 */

/* vignesh  05-05-2023*/

.m-t-19 {
  margin-top: 19px !important;
}
/* vignesh  05-05-2023*/
.w-804px .modal-dialog,
.w-804px .modal-content {
  width: 804px !important;
}
.m-t-18 {
  margin-top: 18px !important;
}

.inventory_datepic input {
  padding-left: 25px !important;
}
.Inventory_Search_outline {
  position: absolute;
  height: 16px;
  width: 16px;
  margin-left: 8px;
  margin-top: 2px;
}
.inv_form {
  height: 28px;
  padding: 0px 10p;
  cursor: poDM Sans;
  border: 0.5px rgba(46, 46, 46, 0.25) solid;
}
.inv_form_relateto {
  height: 36px;
  width: 200px;
  padding: 0px 10p;
  cursor: poDM Sans;
  border: 0.5px rgba(46, 46, 46, 0.25) solid;
}
.inv_form_relateto,
.inv_form:focus {
  border: 0.5px rgba(46, 46, 46, 0.25) solid !important;
}

.arrow-adj1 {
  -webkit-appearance: none;
  background-image: url("../../assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 8px top 6px;
  line-height: 1;
}
.Inv_Exp_DatePicker > div {
  width: 100%;
}
.sett_bill_sett .modal-dialog {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  top: 0px !important;
}

.add_service_popup .modal-dialog {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.text_doc {
  max-width: 80%;
  overflow-x: auto;
}
.security-block {
  width: calc(100% - 61px);
}
.texts_margin {
  margin: 0 0 10px 0;
}
#doc-checks .text_doc ::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
  height: 2px !important;
}
#doc-checks .text_doc ::-webkit-scrollbar-thumb {
  background: rgba(46, 46, 46, 0.5);
  border-radius: 100px;
  height: 1px;
}
#doc-checks .text_doc ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.curs-not-insurance .ant-checkbox > #statemntlogo,
.curs-not-insurance .ant-checkbox > #statemntlogo:hover {
  cursor: not-allowed !important;
}
.security_p_p_email {
  width: 200px;
  margin-left: 84px;
  padding-bottom: 9px;
}
.security_p_p_pass {
  width: 200px;
  margin-left: 61px;
  padding-bottom: 9px;
  padding-right: 40px;
}
.for_sec_eye_pwd {
  margin-left: -35px;
}
.m-l-height {
  height: 16px;
}
.StripeKeyNum {
  height: 34px !important;
  border-radius: 0.25rem;
}
.Sec_bill_stripe_key .ant-input {
  height: 28px !important;
  margin-top: -2px;
}
.for_notifi_pwd {
  width: 290px;
  height: 34px;
  border-radius: 0.25rem;
}
.txt-algn-left {
  text-align: left;
}
/* saranya */
.setting-right-nav {
  height: max-content !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-bottom: 16px !important;
  width: 100% !important;
  margin-left: 64px !important;
}
.p-r-13 {
  padding-right: 13px !important;
}

.setting-right-container {
  background-color: white;
  color: #2d3748;
}
.antdchange .form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.mesg-right {
  color: #b7c1cc;
  margin-right: 35px;
}
.SecurityBlock-FormControl2 .ant-select-selection-item {
  text-align: left;
}
.calendar_sett .ant-select-selection-item {
  text-align: left;
}
#setting_erx_unit {
  display: flex;
  flex-direction: column;
}
#setting_erx_dispenseunit {
  display: flex;
  flex-direction: column;
}
.pres_load .loading-more-div {
  height: 36px !important;
}
.app_rem_drop {
  width: 221px;
}
.app_voice_drop {
  width: 217px;
}

.app_rem_drop1 {
  width: 220px;
  margin-left: 3px;
}

.form-row .app_prac_drop {
  padding-top: 28px;
  padding-right: 5px;
  padding-left: 5px;
}

#setting_erx_medi_content
  .for_autoComplete_bi
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  opacity: 0.5;
}
.favpres-width-600 .modal-dialog {
  max-width: 550px !important;
}
.app-nav-dropdown .dropdown-menu {
  position: absolute;
  bottom: 35px;
  top: -90px;
  z-index: 1001;
}
/* saranya */

.report-right-nav {
  height: max-content !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-bottom: 16px !important;
  width: 100% !important;
  margin-left: 64px !important;
}
.epres-width-600 .modal-dialog {
  width: 550px !important;
  max-width: 550px !important;
}

.fac_alert p {
  text-align: left;
}
.fac_alert {
  padding: 9px 17px 16px 17px;
  border-radius: 8px;
  background: #fff;
  width: 389px;
  height: 128px;
}
.fac_alert1 {
  color: #2e2e2e;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.fac_alert2 {
  color: #2e2e2e;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.err-text-style {
  font-style: "DM Sans", sans-serif;
  font-size: 18px;
}
.billsettingCursor {
  cursor: auto;
}

.billsettingdisabledCursor {
  cursor: not-allowed;
}
.billing_set_ava {
  border-top: 1px solid #ced4da;
}
.billing_set_ava_name {
  margin-top: 16px;
  color: #2e2e2e;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.51px;
}
.partner_disabled {
  cursor: not-allowed;
}
.add_user_with_npi {
  height: 150px !important;
}
.facility_error .is-invalid {
  background-image: none;
}
.ant-input-affix-wrapper::selection {
  border-color: #245c66 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #245c66 !important;
}
.ant-input-affix-wrapper:focus-within {
  border-color: #245c66 !important;
  box-shadow: none !important;
}

.form-label:hover,
.ant-form-item-label label:hover {
  color: #245c66 !important;
}

.form-label:active,
.ant-form-item-label label:active {
  background-color: #245c66 !important;
  color: white !important;
}

.ant-switch-checked {
  background-color: #245c66 !important;
}

.ant-radio-button-wrapper-checked {
  background-color: #245c66 !important;
  color: white !important;
  border-color: #245c66 !important;
}
.addnew {
  background-color: "#245C66 !important";
  border-color: "#245C66 !important";
}
.txtdec {
  text-decoration: "none";
}
.docthera_font_color {
  color: #16424a;
}
