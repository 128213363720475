.vozo-logo {
  width: 110px;
  margin-top: 3px;
}

.top-navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  height: 48px;
}
.tapNotifyMarBot {
  margin-bottom: 20px;
}
.nav-button {
  margin-right: 25px;
}

.ui.search .prompt {
  border-radius: 5px !important;
  background-color: transparent !important;
}

.ui.icon.input > ::placeholder {
  color: #474641 !important;
}

.ui.input > input {
  text-align: left !important;
  margin-right: 10px !important;
}
.ui.icon.input > i.icon:after,
.ui.icon.input > i.icon:before {
  left: 0px;
  position: relative;
  text-align: left;
  top: 35%;
  color: #000000 !important;
}
.ui.icon.input > input {
  padding-right: 2.67142857em !important;
  padding-left: 2.67142857em !important;
  color: #000000 !important;
}

.top-nav-search input {
  background-color: transparent !important;
}
.ui.icon.input > .i.search.icon {
  color: black !important;
}

.ui.icon.input > i.icon {
  left: 0 !important;
}

.mail-top-nav {
  color: #212121;
  /* margin-left: 3px !important;
  margin-top: -6px !important; */
}

.menu-nav-icon {
  margin-bottom: 6px;
}

.nav-menu-st {
  font-size: 14px;
  color: #636d73;
  padding-right: 0px !important;
  padding-left: 0px !important;
  line-height: 45px;
  margin-right: 18px;
  margin-left: 18px !important;
}

.vozo-nav .active {
  font-size: 14px;
  color: #245C66 !important;
  font-weight: 600;
  border-bottom: 2px solid;
}

.nav-menu-st svg {
  margin-right: 4px;
  height: 18px;
  width: 18px;
  margin-bottom: 6px;
}

.nav-logo {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 21px;
  margin-left: 10px;
}
.dropdown-toggle::after {
  display: none;
}

.top-nav-dropdown {
  margin-right: 6px;
  height: 32px;
  padding-left: 5px;
  border: none;
  background-color: #245C66 !important;
  font-weight: 500;
  padding-right: 16px;
}

.top-nav-dropdown svg {
  margin-top: 1px;
  margin-right: 5px;
}

.top-nav-dp-list svg {
  margin-right: 4px;
  margin-bottom: 4px;
}

.top-nav-dp-list {
  color: #2d3748;
}
.drop_logout {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px !important;
}
.drop_logout .dropdown-item:hover {
  border-radius: 6px;
}
.top-nav-dp-list {
  background-attachment: #38482d !important;
}
.top-nav-search input {
  padding-top: 7px !important;
  padding-bottom: 8px !important;
  border: 1px solid #d3d3d3;
}
.top-nav-search input::placeholder {
  color: black !important;
}
.top-nav-search i {
  color: black !important;
}

.pro-icon {
  color: #636d73;
}

.drop-down-list {
  border: 0px !important;
  box-shadow: 0 2px 10px rgb(0 0 0 / 17%);
  border-radius: 3px;
  top: 40px;
}

.disable-text {
  color: #8f8fa4 !important;
  margin-bottom: 5px;
}

.modal-title {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  margin-top: 14px;
}

.form-label {
  color: #2d3748;
}

.modal-footer {
  justify-content: flex-start;
}

.modal-header {
  padding: 1rem 20px;
}

.modal-body {
  padding: 1rem 20px;
}

.close-btn {
  background-color: #ecedef;
}

.fill-btn {
  color: #245C66;
  border: 1px solid #cbd5e0;
}

.save-btn {
  background-color: #245C66;
}

.c-app {
  background-color: #f1f3f0;
  padding: 5px 20px;
  border-bottom: 0px solid #dee2e6;
}

.client-app {
  justify-content: flex-end;
}

.MuiInput-underline:before {
  border: 0px !important;
}

.MuiInput-underline:after {
  border: 0px !important;
}

label + .MuiInput-formControl {
  margin-top: 11px;
}

.date-pic-app {
  margin-left: -20px;
}

.date-pic-app .MuiInputBase-root {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.time-picker-name input {
  display: flex;
  flex-direction: row-reverse;
}

.form-main-time-pic {
  margin-left: -25px;
  width: 100%;
}

.all-day {
  margin-left: 20px !important;
  margin-bottom: 0px;
}

.MuiInputBase-input {
  margin-bottom: -5px !important;
}

.app-popup .modal-dialog {
  max-width: 350px !important;
}

.MuiCheckbox-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.search-pat {
  margin-bottom: 10px;
}

.app-block {
  margin-left: 0px !important;
}

.e-schedule .e-vertical-view .e-header-cells {
  height: 30px;
}

.e-header-day {
  display: inline-flex !important;
}
.e-header-date {
  display: inline-flex !important;
}

.mesg-pointer {
  cursor: pointer;
}

.mesg-popup .modal-dialog {
  max-width: 80% !important;
}
/* jayabalaji */
.top-nav-fixed {
  position: fixed !important;
  width: 100% !important;
  background-color: #FFFFFF !important;
  z-index: 1001;
}
/* jayabalaji */
.drop-d-alig {
  left: -83px !important;
}
.vozo-nav a {
  display: flex !important;
  place-items: center;
}
.vozo-nav a svg {
  margin-top: 6px;
}
.vozo-nav a:last-child {
  margin-right: 20px !important;
}
/* Editing started by Vanitha start */

.dropdown-menu .arrowup {
  width: 0!;
  height: 0!;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  min-width: 3rem !important;
  border-bottom: 10px solid black !important;
  display: block;
  border: none;
  border-radius: none;
}
.dropdown-menu {
  min-width: none !important;
  padding: 0 !important;
}
.ArrowUp {
  height: 25px;
  width: 25px;
  background: #ffffff;
  transform: rotate(45deg);
  margin-top: -13px;
  margin-left: auto;
  margin-right: 41px;
}
.dropdown-menu .card_container {
  width: 258px !important;
  height: unset !important;
  background: #ffffff !important;
  margin-left: -180px !important;
  box-shadow: 0px 6px 4px -4px rgb(0 0 0 / 6%),
    0px 16px 16px -8px rgb(0 0 0 / 6%) !important;
  border-radius: 12px !important;
  padding-bottom: 20px !important;
  margin-top: 10px !important;
  /* margin: 12px; */
  border: none !important;
  display: block;
}

.card_top {
  display: flex;
  margin-top: 7px;
}

hr.horizline {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 242px;
}
.avatarcard_img {
  /* height: 32px;
  width: 32px; */
  margin-top: 6px;
  /* border-radius: 50%; */
}

.avatarcard_edit {
  font-size: 12px !important;
  line-height: 15px !important;
  color: #245C66;
  cursor: pointer;
  font-family: DM Sans, sans-serif;
  margin-left: auto !important;
  margin-right: 20px;
}
.avatarcard_info {
  padding-left: 18px !important;
}
.aname {
  color: #000000;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !;
}
.amail {
  color: #636d73;
  font-family: DM Sans,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px !important;
}

.planpass {
  color: #5f6368;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
}
.pass {
  display: flex;
  margin-top: 10px;
  margin-left: -10px;
  padding-top: 5px;
  padding-left: 7px;
}
.plan {
  display: flex;
}
.log {
  display: flex;
  margin-top: 10px !important;
  margin-left: -10px !important;
  color: #ff5e55 !important;
  font-family: DM Sans, sans-serif;
  font-size: 14px !important;
  line-height: 17px !important;
  cursor: pointer !important;
  padding-top: 5px;
  padding-left: 84px;
}
.bar {
  width: 242px;
  height: 30px;
  background: #f7f7f7 !important;
  border-radius: 4px;
  cursor: pointer;
}
.ico_word {
  padding-left: 7px;
}
.lock {
  display: flex;
}

/*Ended*/

/* .cal_container {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 6px;
  height: 374px;
  width: 400px;
} */
/* .cal_first {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#2d65e8 60%, #ffffff 40%);
} */

.cal_first {
  /* background: rgba(221, 232, 253, 0.4); */
  height: 200px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
}

.cal_container .modal-dialog {
  width: 400px !important;
  border: none !important;
  box-shadow: none !important;
}
.cal_container .modal-body {
  padding: 0px !important;
}
.cal_first .cal_image {
  width: 402px;
  height: 200px;
}

.close_icon_main {
  /* height: 14px;
  width: 14px;
  margin-left: auto;
  margin-right: 170px; */
  margin-top: -3px;
  color: #ffffff;
  font-size: 18px;
}
.close_icon_main svg {
  margin-top: 17px;
}
.cal_sec_row {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: 0.0025em;
  color: #232065;
  margin-top: 16px;
}
.cal_secondrow_main {
  padding-left: 20px;
}
.cal_sec_row_one {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  letter-spacing: 0.0025em;
  color: #b0b0b0;
  margin-top: 3px;
  margin-right: 26px;
}
.cal_sec_row_two {
  background: #2d65e8;
  border-radius: 4px;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 20px;
  padding: 8px 24px;
}

/* Editing started by Vanitha end */
/* christina start */
.top-nav-upgrade-button {
  margin-right: 4.5px;
  height: 32px;
  padding-left: 5px;
  background-image: linear-gradient(to right, #2347e7, #c624ff);
  font-weight: 500;
  font-size: 13px;
}

.top-nav-upgrade-button svg {
  margin-bottom: 2px;
  margin-right: 7px;
  margin-left: 2px;
}

/* christina end */
.user-image-pic {
  height: 25px;
  width: 25px;
}

.topNavBarAvatar {
  font-family: DM Sans,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 18px;
  text-align: center;
}
.topNavBarAvat .ant-avatar-string {
  width: 44px;
  margin-top: 0px;
}
.topNavBarAvat1 .ant-avatar-string {
  width: 44px;
  margin-left: 0px;
  margin-top: 4px;
}
.top-nav-msg-box {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 5px;
}
.top-msg-icon {
  position: relative;
  margin-right: 16px;
}
.top-nav-rem-pad button {
  padding-left: 0px !important;
}
.top-nav-add-task {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin-right: 5px;
}
.top-nav-pat-search .ui.input.icon {
  height: 32px !important;
  margin-right: 13px;
}
/*Ahamed_Changes_05-07-22*/

.top-nav-blocker .ArrowUp {
  margin-right: 23px !important;
}
.top-nav-blocker .dropdown-menu.card_container {
  padding: 0px 18px 20px 18px !important;
}
/*Ahamed_Changes_05-07-22*/
.dropdown-menu .app-notification {
  margin-left: -250px;
}
.bell-icon1 g {
  stroke: red;
  fill: red;
}
.bell-icon g {
  stroke: #212121;
  fill: none;
}

.for-tab-style .ant-tabs-tab:not(.ant-tabs-tab:nth-child(2)) {
  margin-left: 20px;
}

.sec-msgs-left .ant-tabs-nav-list:nth-child(2) {
  margin-left: 44px !important;
}

/* Updated by Vanitha-- Start */

.navpopname {
  padding-left: 0px !important;
}

.dropdown-menu.topnavcard {
  margin-left: -189px !important;
}

.topnav_card_top {
  border: none !important;
}

.topnav_popup_pass {
  background: none !important;
  margin-top: 0px;
}

.topnav_popup_pass_icon {
  font-size: 24px;
}

.topnav_popup_passdisplay {
  align-items: center;
}

.topnav_avatarcard_ {
  margin-top: -42px;
}

.plan:hover,
.pass:hover {
  background-color: #f7f7f7 !important;
  opacity: 0.7;
  color: #000000;
  cursor: pointer !important;
}

.plan_myplan {
  margin-right: 7px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  margin-top: 0px !important;
}

/* Updated by Vanitha-- End */

/* Vanitha 26-08-22 */
.topnav_popavatar {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Vanitha 26-08-22 */
/* Vanitha 30-08-22 */
.Myplan_alt {
  padding-left: 7px !important;
  margin-left: -9px !important;
}

.pass_alt {
  padding-bottom: 5px !important;
}

/* Vanitha 30-08-22 */
.back-icon .chat-back-arrow {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 0px;
}
.mr-t-5 {
  margin-top: 5px;
}
.top-nav-bg-col {
  background: #a8aebe !important;
}
.top-nav-pro-cap {
  line-height: 28px;
  margin-left: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-nav-drop-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-nv-link-color {
  color: #5f6368;
}
.top-nv-pat-srch.for_autoComplete_bi_Patient {
  margin-top: -6px !important;
  padding-top: 0px !important;
  margin-right: 16px !important;
  background-color: #EFF0F1;
  font-family: 'DM Sans', sans-serif !important;
}
.input-bg {
  background-color: #EFF0F1 !important;
}

.top-nv-pat-srch .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 4px !important;
}
.aud-top-nav1 {
  height: 40px;
  width: 250px;
}

.aud-top-nav2 {
  height: 36px;
  width: 400px;
  margin-left: 8px;
  margin-right: 12px;
  margin-bottom: -10px;
}
.disabled-link {
  cursor: not-allowed !important;
}

.top_nav_display {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.chatPopSize {
  width: 100% !important;
  height: 100% !important;
}

.taskaddcolor {
  color: #1890ff;
  cursor: pointer;
}
