.service {
  padding-left: 15px;
  /* padding-top: 5px; */
  color: #718096;
  font-weight: 400;
  align-self: center;
}
.mg {
  margin-left: 5%;
}
.mgs {
  margin-left: 25%;
}
.filter {
  border: 1px solid #d9d9d9;
  font-size: 32px;
  float: left;
  padding: 5px;
  margin-top: 2px;
  color: #718096;
  border-radius: 5px;
}
.ant-checkbox-wrapper {
  margin-top: 3px !important;
  margin-right: 10px;
  margin-left: 10px;
}
.for_login_c_b_mt .ant-checkbox-wrapper {
  margin-top: 19px !important;
}
.paid {
  background-color: #c9f7e6;
  color: #18b27a;
  border-radius: 15px;
  padding: 3px 18px 3px 18px;
  font-weight: 500;
  font-size: 14px;
  font-family: DM Sans, sans-serif;
}
.unpaid {
  background-color: #ffe5e3;
  color: #e55e53;
  border-radius: 15px;
  padding: 3px 18px 3px 18px;
  font-weight: 500;
  font-size: 14px;
  font-family: DM Sans, sans-serif;
}
.partpaid {
  background-color: #e8e1f2;
  color: #8d79ad;
  border-radius: 15px;
  padding: 3px 18px 3px 18px;
  font-weight: 500;
  font-size: 14px;
  font-family: DM Sans, sans-serif;
}
.unbilled {
  background-color: #ffe6ff;
  color: #e600e6;
  border-radius: 15px;
  padding: 3px 18px 3px 18px;
  font-weight: 500;
  font-size: 14px;
  font-family: DM Sans, sans-serif;
}

.date {
  border: 0px solid #d9d9d9 !important;
  background-color: #fafafa;
}
.bg {
  background-color: #fafafa;
  border-radius: 0px;
  height: 30px;
}
.pl {
  padding-left: 0px !important;
}
.ml {
  border-radius: 4px 0px 0px 4px;
  background-color: #fafafa;
}
.mr {
  border-radius: 0px 4px 4px 0px;
  background-color: #fafafa;
}
.cd {
  float: right;
}
.cde {
  /* float: right; */
}
.right {
  float: right;
}
.p-l-r-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.dere {
  float: right;
}
.new11 {
  margin-top: 180px;
}
.head {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  background-color: #f1f5f8;
  /* Blue */

  color: #245C66;
}
.ma {
  border-radius: 4px 0px 0px 4px;
}
.mb {
  border-radius: 0px;
}
.mc {
  border-radius: 0px 4px 4px 0px;
}
.micon {
  float: left;
}
/*jeyabalaji*/
.iborder {
  /* display: flex; */
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px 100px 5px 10px;
  width: 100% !important;
  height: 64px;
}
/*jeyabalaji*/
/*jeyabalaji*/
.bimobi {
  font-size: 24px;
  color: #57bf7f;
}
.icon-border {
  border-radius: 12px;
  margin-left: 10px !important;
  padding-top: 5px;
  width: 24px;
  height: 24px;
}
/*jeyabalaji*/

.number {
  font-size: 20px;
  font-weight: 600;
  line-height: 81.2px;
  align-self: center;
  color: #000000;
}
/*saravanan*/
.name {
  font-size: 18px;
  font-weight: 500;
  font-family: DM Sans, sans-serif;
  line-height: 16.94px;
  color: #2e2c34;
}
/*saravanan*/
.sp {
  padding-left: 10px;
  text-align: initial;
}
/*saravanan*/
.mobile {
  color: #f47ec3;
  font-size: 20px;
}
/*saravanan*/
/*saravanan*/
.envelope {
  color: #ff5e45;
  font-size: 20px;
}
/*saravanan*/
/*saravanan*/
.callout {
  font-size: 20px;
  color: #41d7fb;
}
/*saravanan*/
.css-yk16xz-control {
  min-height: 20px !important;
}
.create-appt {
  height: 34px;
}
.active-badge {
  background-color: white;
  margin-top: 0px;
  padding-left: 0px !important;
  font-weight: 400;
}
.pate {
  margin-top: 0px;
  width: 565px;
}
.had {
  padding-left: 0px;
}
.vert {
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
  width: inherit;
  text-align: initial;
  padding-left: 20px;
}
/* .appt-block-main-bill {
  width: 99%;
} */
.tabd {
  text-align: justify;
  display: flex;
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 16px;
}
.btn-block {
  width: auto;
}
.vfd {
  background-color: #2066c2 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #c1c1c1;
}
.remit {
  max-width: 87%;
}
.remito {
  /* max-width: 100%; */
}
.talbe {
  width: 98%;
}
.ant-form-item {
  margin-bottom: 0px !important;
}
.pemed {
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.tabd span {
  margin-right: 5px;
}
.revert {
  padding: revert;
}
.ant-space {
  width: 80%;
  margin-left: 20px;
  margin-top: 8px;
}
.serviceq {
  padding-left: 15px;
  padding-top: 5px;
  color: #718096;
  font-weight: 400;
  /* width:110px; */
}
.patie {
  width: 110px;
  padding-right: 0px !important;
  padding-left: 5px;
}

.content {
  text-align: initial;
  padding: 10px 25px 0px 25px;
}
.debit {
  color: #151515;
  font-size: 12px;
  /* line-height: 16.94px; */
  line-height: 30.94px;
  font-weight: 500;
  font-family: " 'DM Sans', sans-serif";
}
.dollor {
  color: #151515;
  /* background-color: #2D3748; */
  font-weight: 700;
  font-size: 24px;
  font-family: " 'DM Sans', sans-serif";
  line-height: 24.2px;
}
@media only screen and (max-width: 1300px) {
  .dollor {
    font-size: 20px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1200px) {
  .dollor {
    font-size: 16px;
  }
}
.debit_text,
.adjustment_text {
  float: left;
  color: #2e2c34;
  /* background-color: #2D3748; */
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
}
#arr_up_icon svg:not(:root) {
  border-radius: 4px !important;
  background-color: #20c9ac;
  overflow: hidden;
}
#arr_down_icon svg:not(:root) {
  border-radius: 4px !important;
  background-color: #fc3400;
  overflow: hidden;
}
.arrow_up {
  float: right;
  color: #ffff;
  /* background-color: #2D3748; */
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
}
.payment_text,
.credit_text {
  float: left;
  color: #2e2c34;
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
}
.arrow_down {
  float: right;
  color: #ffff;
  /* background-color: #2D3748; */
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
}
/*saravanan*/
.blue {
  border-top: none !important;
  height: 1px;
}
/*saravanan*/
.green {
  border-top: none !important;
  height: 1px;
}
/*saravanan*/
.red {
  border-top: none !important;
  height: 1px;
}
/*saravanan*/
/*saravanan*/
.orange {
  border-top: none !important;
  height: 4px;
}
/*saravanan*/
.table {
  margin-top: -17px !important;
}
.css-1wa3eu0-placeholder {
  width: max-content;
}
.spn {
  font-size: 14px;
  font-weight: 400;
}
.out {
  display: table-caption;
}

.badge span {
  font-size: 16px;
  color: black;
  font-weight: 500;
}
.recharts-legend-wrapper {
  display: none;
}
.log img {
  /* margin-top: 5%; */
  width: 90%;
  margin: -5% 0% 5% 14%;
}
.labels {
  float: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #7d8592;
}
.login-input {
  margin-bottom: 20px !important;
  border: 1px solid #d8e0f0;
  border-radius: 8px;
  height: 48px;
}
.welcome-text {
  text-align: center !important;
}
.sign {
  width: 170px !important;
  height: 45px;
  font-size: 16px;
  padding: 3px;
  border-radius: 14px;
  margin-left: 32px;
}
.site-form-item-icon {
  font-size: 25px;
  color: #7d8592;
}
.ren {
  font-size: 16px;
  color: #7d8592;
  text-decoration: none;
}
.do-you-text {
  margin-left: 32%;
}
.back {
  background-color: white;
  width: 75%;
  margin-top: 7%;
  height: 85%;
  /* margin-bottom: 34px; */
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px #c4cbd61a;
  margin-left: 15%;
}
.edit {
  border: 1px solid #cbd5e0;
}
.upload {
  margin-top: 0px !important;
}
.upload-btn {
  margin-right: 150px;
}
.send {
  color: #2066c2;
  background: #e9f0f9;
  mix-blend-mode: normal;
  border: 0px;
  border-radius: 4px;
  margin-left: -55px;
}
.fax {
  background-color: #ffffff;
  color: #2066c2;
  border: 1px solid #cbd5e0;
}
.ico {
  font-size: 100px;
  color: #bfc0c3;
}
.choose {
  color: #4c5a6e !important;
  background-color: #fafafa;
  border: 1px solid #b6bac1;
  font-size: 14px;
  font-weight: 600;
}
.choose:hover {
  background-color: #e2e0e0 !important;
  color: white;
}
.drag {
  font-size: 24px !important;
  font-weight: 500;
  color: #263446;
}
.modal-footer {
  border-top: 0px;
  align-self: flex-start;
  padding-left: 4%;
}
.ant-upload {
  margin-left: 0px;
}
.ant-upload-list {
  padding-bottom: 10px;
}
.w-a {
  width: auto;
}
.heig {
  height: 100%;
}
.boxw {
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 0px;
  height: 92px;
  /* padding-right: 25px; */
}
.date-input {
  min-width: 115px;
}
.pfd {
  padding-left: 15px;
  padding-right: 10px;
}
.mar-min {
  margin-bottom: -12px;
}
.m-t-0 {
  margin-top: 0px;
}
.p-t-16 {
  padding-top: 16px !important;
}
.p-l-2 {
  padding-left: 2px !important;
}
.totalss {
  color: #245C66 !important;
}
.wrt button {
  width: 80px;
  height: 32px !important;
}
.m-r-13 {
  margin-right: 13px;
}
.pates {
  width: 567px;
  margin: 0px;
}
.pareport {
  margin-right: -16px;
}
.modal-header .close {
  font-size: 1.5rem !important;
  padding: 0.5rem !important;
  font-weight: normal !important;
  padding: 0px 5px 5px 5px !important;
  background-color: #f4f4f4 !important;
  border-radius: 50% !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 20px !important;
}
.close {
  line-height: 0.3 !important;
}
.MuiButton-contained {
  background: #fafbfd !important;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1) !important;
  border-radius: 4px 0px 0px 4px !important;
}
.MuiButton-contained:hover {
  background-color: rgb(255, 255, 255) !important;
}
.MuiButton-contained:active {
  background-color: rgb(255, 255, 255) !important;
}
.hhh {
  margin-bottom: 20px;
}
.m-l---15 {
  margin-left: 32%;
  width: 100%;
  background: #f6f7f9 !important;
}
.ient {
  display: inline-flex;
  font-style: normal;
  margin-right: 2%;
}
.ient .ui.input {
  max-width: 70% !important;
  position: relative;
  font-weight: 400;
  height: 34px !important;
  font-style: normal;
  display: inline-flex;
  background-color: white;
  border-radius: 4px;
}
.vitals-btn---5 {
  background-color: white;
  border: 1px solid #cbd5e0;
  color: #718096;
  width: 25% !important;
  height: 38px !important;
}
.ex-to-fl-btn {
  height: 32px;
  width: 115px;
  margin-left: 20px;
}
/*saravanan*/
.input-size--1 {
  font-family: DM Sans, sans-serif;
  border: 1px solid #cbd5e0;
  height: 33px !important;
  color: black !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}
/*saravanan*/
.input-size--1:hover {
  background-color: #dddcdc;
  color: black !important;
  border: 1px solid #cbd5e0;
}
#ofiz {
  margin-left: 15%;
  padding-left: 12%;
  height: 34px !important;
}
.vule {
  height: 10% !important;
}
.moo .ins-p-s .ant-picker {
  height: 35px !important;
  border-radius: 0.25rem;
}
.input-size--2 {
  float: left;
  font-family: DM Sans, sans-serif;
  color: black;
  height: 33px !important;
  border-color: black;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
}
.m-l----15 {
  margin-left: 2%;
  max-width: 100%;
  background: #f6f7f9 !important;
}
.chartey {
  padding-left: 0.9%;
  padding-top: 10%;
}
.ins-top-btn--5 {
  padding-right: 0px;
  margin-left: 1%;
  width: 100% !important;
}
#b-l-34 .form-control {
  max-width: 100%;
  margin-left: 1%;
}
.m-l-15--4 {
  margin-left: 7%;
  margin-right: 7%;
}
/*saravanan*/
.service--007 {
  margin-left: 16px;
  color: #718096;
  font-weight: 400;
  align-self: center;
}
/*saravanan*/
.create-apptey {
  background: #f6f7f9 !important;
  height: 32px !important;
  font-weight: 500 !important;
  color: black;
}
.input-sizeeeee1 {
  border-color: black !important;
  height: 32px !important;
  border-radius: 3px;
}
/*saravanan*/
.prob-head-title--006 {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 20px !important;
  /* color: #2e2c34 !important; */
  font-family: DM Sans, sans-serif;
  font-weight: 400;
  line-height: 16px;
}
/*saravanan*/
/*saravanan*/
.debit--2 {
  color: #84818a;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 500;
  font-style: DM Sans,sans-serif;
}
/*saravanan*/
.dollor--2 {
  color: #20c9ac;
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  margin-bottom: 6px;
}
.p-t-51-1 {
  margin-left: -4px;
  padding-top: 7px;
  padding-left: 20px;
  margin-right: 16px;
}
/*saravanan*/
.for-prod-rep-res-checkbox {
  width: 30% !important;
}
.for-prod-rep-res-checkbox .ant-checkbox-wrapper {
  margin-top: 0px !important;
}
.chartey--1 {
  padding-top: 9% !important;
}
.m-b-f {
  padding-top: 2%;
  margin-left: 20%;
}
.derey {
  color: #245C66;
  padding-right: 8%;
  float: right;
}
.dereey {
  color: #245C66;
  padding-right: 2%;
  float: right;
}
.p_p_t_1_2 {
  margin-left: 50% !important;
  margin-right: 50% !important;
}
.out_pres_export_button {
  border: 1px solid #2d3748 !important;
  background: #ffffff !important;
}
.def_search .ui.icon.input > input {
  background: #ffffff !important;
  height: 32px !important;
}
.align_grand_total {
  margin-top: 2%;
}
/*saravanan*/
.productivity_report_footer {
  margin-top: 4% !important;
  margin-left: 33%;
  margin-bottom: 2% !important;
}
/*saravanan*/
.pro_report_export {
  background: #f6f7f9;
  border: 1px solid #718096;
  box-sizing: border-box;
  border-radius: 4px;
}
.bical {
  font-size: 100px;
  color: #ebeaed;
  margin-right: 10% !important;
  border-radius: 4px !important;
}
.icon-border_cal {
  margin-left: 54% !important;
  border-radius: 4px;
  padding-top: 5px;
  width: 40px;
  height: 40px;
}
.biclock {
  font-size: 100px;
  color: #ebeaed;
  margin-right: 10% !important;
}
.icon-border_clock {
  margin-left: 54% !important;
  border-radius: 4px;
  padding-top: 5px;
  width: 40px;
  height: 40px;
}
.ren_pro_report {
  font-size: 16px;
  color: #000000;
  text-decoration: none;
}
.user_report_search .ui.icon.input > input {
  background-color: #fff !important;
}
.tele_health_expoxt_btn {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.tele_bg_back_ {
  padding-bottom: 40px !important;
}
.telehealth_select {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.telehealth_select .form-control {
  height: 32px !important;
}
.telehealth_report___ {
  width: 15% !important;
  height: 38px !important;
}
.tele_rep {
  max-width: 100% !important;
}
.tele_rep .form-control {
  height: 32px !important;
  /* width: 100% !important; */
}
.tele_search .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
}
.trans_action_export {
  background-color: white !important;
  border: 1px solid #cbd5e0 !important;
  color: #718096;
  width: 100px !important;
  height: 35px !important;
}
.trans_action_search .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
}
.trans_office_search .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
}
.credit_table_head > .MuiTableContainer-root {
  margin-top: 27px !important;
}
.trans_action_print {
  background-color: white !important;
  border: 1px solid #cbd5e0 !important;
  color: #718096;
  width: 100px !important;
  height: 35px !important;
}
.product_proce_search .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
}
.product_procedure_date_input > .ant-picker {
  color: yellow !important;
  height: 32px !important;
}
/*saravanan*/
.remittance_table_export {
  width: 125px !important;
  height: 35px !important;
}
/*saravanan*/
/*saravanan*/
.remittance_table_print {
  background-color: #fafafa !important;
  border: 1px solid #cbd5e0 !important;
  color: #718096;
  width: 100px !important;
  height: 35px !important;
}
/*saravanan*/
.remittance_table_add_eob {
  background-color: white !important;
  border: 1px solid #245C66 !important;
  width: 120px !important;
  height: 35px !important;
}
.under_paid_search_1 .ant-input {
  border-radius: 4px !important;
}
.under_paid_search_2 .ant-input {
  border-radius: 4px !important;
}
/*saravanan*/
.appoint_report_table_btn1 {
  border: 1px solid #245C66;
  color: #718096;
  width: 100px !important;
  height: 35px !important;
}
/*saravanan*/
/*saravanan*/
.appoint_report_table_btn2,
.appoint_report_table_btn3,
.appoint_report_table_btn4 {
  border: 1px solid #cbd5e0;
  color: #718096;
  width: 100px !important;
  height: 35px !important;
}

.appoint_report_table_btn4:hover{
  color:#245C66 ;
}
/*saravanan*/
.mr-tp-12 {
  margin-top: -12px;
}
.mrg-tp-6 {
  margin-top: 6px;
}
.appoint_report_search .ui.icon.input {
  background-color: #fff !important;
  height: 34px !important;
  width: 155px;
  border-radius: 4px;
  margin-right: 21px;
  border: 1px solid #cbd5e0;
}
.appoint_report_search .ui.icon.input > input {
  border: none !important;
}
/* .appoint_report_search {
  margin-left: 15px !important;
} */
.appoin_report_form .form-control,
.antd-dd-apt_repo .ant-select-selector {
  width: 155px !important;
  height: 34px !important;

  border: 1px solid #cbd5e0 !important;
}
.appoin_report_date_picker {
  position: relative;
}
.appoin_report_date_picker .ant-picker {
  height: 34px !important;
  width: 140px !important;
  color: #000000 !important;
}
.appoin_report_update {
  height: 33px !important;
  border-radius: 4px;
  margin-right: 20.5px;
  width: 96px !important;
}
/*saravanan*/
.head_user {
  padding-left: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  background-color: #f1f5f8;
  color: #245C66;
  border-radius: 10px;
  margin: 0px 10px;
}
/*saravanan*/
.appt-block-main-1 .ant-tabs-nav {
  padding-left: 24px !important;
}
.user_check_doctor {
  padding-top: 5px !important;
}
#for-check {
  margin-top: 1% !important;
}
#for-check .ant-checkbox-wrapper {
  margin-top: 3px !important;
  margin-right: 10px;
}
.for-prod-rep-fil,
.antd-dd-inv_repo .ant-select-selector {
  height: 38px !important;
}
.user_docter_check {
  margin-top: -10px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
}
.user_staff_doctor {
  padding-top: 5px !important;
}
.user_staff_check {
  margin-top: -10px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
}
.user_staff_search .ui.icon.input > input {
  background-color: #fff !important;
}
.trans_table {
  margin-top: 25px !important;
}
.bg-back_report {
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
}
.unmatched_table {
  margin-top: 25px !important;
}

.product_proce_table {
  margin-top: 25px !important;
}

.outgoing_table {
  margin-top: 25px !important;
}
.bg-back_outgoing {
  background-color: #fcfcfc;
  padding-top: 20px;
  padding-bottom: 8px;
}
.sec-row-fam {
  border-bottom: 0px solid #cbd5e0 !important;
  height: 0px;
}

.icon-appt {
  padding: 15px 10px 0px 0px;
}
.appt-dis {
  padding: 0px 20px;
}
.appt-content1 {
  margin-left: 10px !important;
}

.bg-back_user_report {
  background-color: #fcfcfc;
  margin-top: 10px !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.user-rep-staff-check-box {
  padding-top: 3px !important;
  margin-left: -3px !important;
}
.user_search .ui.input {
  height: 34px !important;
}
.save-btn_user {
  height: 34px;
  background-color: #245C66;
  font-weight: 500;
}
.invoice_search_patient .ui.icon.input > input {
  width: 170px !important;
  height: 34px !important;
  background-color: #fff !important;
}
.invoice_search_provider .ui.icon.input > input {
  width: 170px !important;
  height: 34px !important;
  background-color: #fff !important;
}
.invoice_search_invoiceid .ui.icon.input > input {
  width: 160px !important;
  height: 34px !important;
  background-color: #fff !important;
}
.invoice_status .form-control {
  width: 160px !important;
  height: 34px !important;
  background-color: #fff !important;
}
.invoice_date .ant-picker {
  height: 34px !important;
}
.bg-back_report_invoice {
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 20px !important;
}
.invoice_date .ant-picker {
  height: 34px !important;
  background-color: #fff;
}
.patient-age .css-1wa3eu0-placeholder {
  color: #245C66 !important;
}
/*saravanan start*/
.patient-age-size .css-1wa3eu0-placeholder {
  color: #245C66 !important;
  font-size: 16px !important;
  padding-top: 3px;
}
.patient-age-size .css-yk16xz-control .css-g1d714-ValueContainer,
.patient-age-size .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer {
  height: 32px !important;
}
/*saravanan end*/
.w-15-prpt {
  width: 17% !important;
}
.w-15-prt {
  width: 7% !important;
}
.w-15-prt .css-yk16xz-control {
  height: 36px !important;
  border-radius: 6px;
}
.w-15-pat {
  width: 9% !important;
}
.w-15-pat .css-yk16xz-control {
  height: 36px !important;
  border-radius: 6px;
}
/*saravanan start*/
.pat-r-f {
  font-size: 15px !important;
}
/*saravanan end*/
.pat-r-l {
  margin-left: -73% !important;
  font-size: 15px !important;
}
._pat-rep-tab .ui.icon.input > input {
  background-color: #ffffff !important;
}
.patie_report_export {
  background-color: #f5f5f5 !important;
  border: 1px solid #cbd5e0 !important;
  color: #718096;
  width: 100px !important;
  height: 35px !important;
}
.for-invoice-stat-hei {
  height: 38px !important;
}
.for-invoiceID {
  margin-left: 30px;
}
.bg-back-invoices {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
/*saravanan*/
.pat-r-a {
  margin-left: -78% !important;
  font-size: 15px !important;
}
/*saravanan*/
.pat-r-p {
  margin-left: -72% !important;
  font-size: 15px !important;
}
.pat-r-la {
  margin-left: -69% !important;
  font-size: 15px !important;
}
/*saravanan*/
.patient-report-save {
  margin-top: 28px !important;
  margin-left: 20px !important;
}
/*saravanan*/
.input-size-pat_save {
  height: 36px !important;
  border-radius: 4px;
}
/*saravanan*/
.user_search_btn {
  margin-left: 20px;
  padding: 2px 20px 4px 20px;
  margin-top: 24px;
}
/*saravanan*/
.user_serc .input {
  width: 260px !important;
}
.pat_ss .input .prompt {
  background-color: #ffffff !important;
}
.inv-btn-srch {
  height: 38px !important;
}
/*jeyabalaji*/
.out-presc-search .ui.icon.input {
  width: 200px;
  margin-right: 21px;
  height: 34px;
  background-color: white;
}
/*jeyabalaji*/
/*saravanan*/
.fil-for-out-going {
  height: 34px !important;
  width: 126px !important;
  margin-right: 20px;
}
.pat-report-search .ui.icon.input {
  width: 200px !important;
  min-width: 150px;
  height: 32px;
  margin-top: 4px;
}
.pro-pdr-appt .ui.icon.input {
  height: 38px;
  margin: 0px 10px 0px 20px;
}
.for-unselect-all-width {
  white-space: nowrap;
  width: 95px !important;
}
/* ----------------------By Jeyabalaji ---------------------------- */
.prod-rep-srch-box-1-from-date {
  width: 20% !important;
  min-width: 250px !important;
}
.inv-rep-res-srch {
  width: 20% !important;
}
.inv-rep-res-srch .input {
  width: 100% !important;
}
.inv-rep-res-srch-but {
  width: 10% !important;
}
.inv-rep-res-srch-date {
  max-width: 20% !important;
  margin-left: 5px;
}
.inv-rep-res-srch-date .ant-picker-range {
  width: 100% !important;
}

.pat-rep-box-2 {
  margin-top: 0px !important;
  padding-bottom: 16px !important;
}
.w-15-prpt .ant-picker {
  height: 34px !important;
}
/* saravanan  start*/
.pat-rep-age-box {
  height: 32px !important;
  border-radius: 4px !important;
}
.pat-rep-age-box.ant-input::placeholder {
  color: black !important;
  padding-top: 2px;
}

.pat-rep-srch-box {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: #f7f7f7;
}
.pat-rep-hr {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.patr-m-t-12 {
  margin-top: 16px !important;
}

.pat-rep-box-1 {
  margin-left: 20px !important;
}
.pat-rep-box-1,
.pat-rep-box-2 {
  flex-wrap: wrap;
}
/* saravanan  end*/
.prod-rep-head-search {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-bottom: 24px !important;
}
.prod-proc-rep-search-box {
  height: 56px !important;
}

.for-prod-rep-head-1 {
  margin-bottom: 0px !important;
}
/* updated by saravanan start */
.for-pat-rep-dos-res {
  margin-top: 9px;
  /* width: 140px !important; */
}
/* updated by saravanan end */
.appt-dis-for-top {
  border: 1px solid #ebeaed;
  box-sizing: border-box;
  border-radius: 4px;
}
.appt-dis-for-top-1 {
  border: 1px solid #ebeaed;
  box-sizing: border-box;
  border-radius: 4px;
}
.for-prod-rep-foot {
  padding-top: 12px !important;
  height: 100px !important;
  margin-bottom: 24px !important;
  padding-bottom: 12px !important;
  padding-left: 24px !important;
}

.w-15-prpt {
  width: 17% !important;
}
.for-pat-rep-res-fdate {
  width: 180px !important;
  /* margin-left: 12px !important;    */
  /* updated by saravanan start*/
}
.for-pat-rep-res-sex {
  width: 100px !important;
  margin-left: 12px !important;
}
.for-pat-rep-res-age {
  width: 100px !important;
  margin-left: 12px !important;
}
.for-pat-rep-res-race {
  width: 12% !important;
  /* margin-left: 12px !important; */
}
.for-pat-rep-res-ethn {
  width: 15% !important;
  margin-left: 12px !important;
}
.pat-rep-serch-res-box input {
  border: 1px solid #dbdbdb !important;
  border-radius: 4px;
}
/* updated by saravanan start*/

.for-invoice-stat-hei {
  height: 38px !important;
}
.for-invoiceID {
  margin-left: 20px;
}

.bg-back-invoices {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.pat-rep-serch-res-box .input {
  width: 100% !important;
}
.pat-rep-serch-res-box {
  text-align: left !important;
}
.inv-btn-srch {
  height: 38px !important;
  margin-left: 15px !important;
}
.for-produc-proc-head {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
  padding-left: 24px !important;
}
.for-prod-proc-date .ant-picker {
  height: 32px !important;
  margin-left: 2px !important;
}
.prod-proc-update-but {
  margin-left: 17px !important;
}
.invoice-srch-box-1 {
  margin-bottom: 0px !important;
}
.invoice-srch-box {
  margin-top: 0px !important;
  margin-bottom: 24px !important;
}
.invoice-srch-pat .ui.search .prompt {
  background-color: #fff !important;
}
.user-rep-head {
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-bottom: 24px !important;
}
.user-rep-head-1 {
  margin-top: 0px !important;
}
.invoice-rep-head {
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-bottom: 24px !important;
}
.prod-proc-date-bet {
  margin-left: 14px !important;
  padding-left: 0px !important;
  margin-right: 10px !important;
}
.prod-proc-rep-title {
  margin-left: 4px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.for-prod-proc-srch-box-nd {
  margin-bottom: 0px !important;
}
/* ----------------------By Jeyabalaji ---------------------------- */
.rem-rep-head-for-res {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #2e2c34;
  padding: 20px !important;
}

.for-rem-rep-sty-for-res {
  /* width: 25% !important; */
  /* min-width: 250px !important; */
  /* padding-right: 20px !important; */
  /* dhilip start  */
  width: 25% !important;
  /* min-width: 250px !important; */
  padding-right: 60px !important;
  padding-left: 0px;
  /* dhilip end  */
}
.for-rem-rep-sty-for-res4 {
  width: 25% !important;
  /* min-width: 250px !important; */
  /* padding-right: 20px !important; */
}
.for-rem-rep-sty-for-box-hgt {
  height: 100px !important;
}
/* jeyabalaji */
.icon-border svg {
  height: 16px;
  /* width: 16px; */
  margin-top: -12px;
}
/* jeyabalaji */
.for-total-data-in-rem-rep {
  padding-top: 20px !important;
  margin-left: 10px !important;
  padding-left: 0px !important;
}

.for-rem-rep-total-val {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #2d3748;
}

.for-mail-for-rem-rep {
  background-color: rgba(255, 94, 69, 0.1);
}
.for-sec-roe-for-rem-rep-srch {
  margin-bottom: 0px !important;
}
.for-rem-rep-srch-box-bg {
  background-color: #f7f7f7;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
  padding-left: 20px !important;
}
/* jeyabalaji */
.for-rem-rep-res-srch-box-sty ::placeholder {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
/* jeyabalaji */
.for-sec-roe-for-rem-rep-srch input {
  height: 32px !important;
}
.for-rem-rep-srch-text1 {
  margin-top: 7px !important;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #718096;
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.for-rem-rep-res-srch-box-sty {
  width: 20% !important;
}
/* jeyabalaji */
.for-rem-rep-res-srch-box-sty-fd {
  width: 16% !important;
}
/* jeyabalaji */
.for-rem-rep-res-srch-box-sty-d-m-y {
  width: 33% !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .btn-rem-rep-d-m-y {
  border: 1px solid #dbdbdb !important;
  width: 23% !important;
  /* border-radius: 5px !important; */
}

.for-rem-rep-res-srch-box-sty-d-m-y ::placeholder {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
/* jeyabalaji */
.for-rem-rep-res-srch-box-sty-fd > input {
  width: 100% !important;
  border: 1px solid #dbdbdb !important;
  border-radius: 5px !important;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
/* jeyabalaji */
.for-rem-rep-res-srch-box-sty .input {
  width: 100% !important;
  height: 32px !important;
}
.for-rem-rep-res-srch-box-sty .input > input {
  width: 100% !important;
  height: 32px !important;
  border: 1px solid #dbdbdb !important;
  border-radius: 6px !important;
}
.for-rem-rep-res-srch-box-sty-btn {
  width: 7% !important;
}
/* jeyabalaji */
.rem-rep-for-table-box {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  /* padding-top: 20px !important; */
  /* padding-bottom: 20px !important; */
  border: 1px solid #dbdbdb !important;
  border-radius: 6px !important;
  overflow: hidden;
}
/* jeyabalaji */
.rem-rep-for-table-box .MuiTableContainer-root {
  margin-top: 0px !important;
}
.for-rem-rep-tab-head {
  text-align: left;
  font-family: DM Sans,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #245C66;
  padding: 20px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.for-rem-rep-table-data-pname {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #245C66;
}
.for-rem-rep-table-data-update {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #245C66;
}
/* jeyabalaji */
.rem-rep-head-tit {
  margin-top: 0px !important;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px !important;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #2e2c34;
}
/* jeyabalaji */
/* ----------------------By Jeyabalaji ---------------------------- */

#bil-sum .MuiButton-contained {
  background: #fafbfd;
  box-shadow: 0px 0px 0px rgb(20 46 110 / 10%) !important;
  border-radius: 0px;
}

#bil-sum .MuiButton-contained:hover {
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1) !important;
  background: #ffffff !important;
  border-radius: 4px 0px 0px 4px;
}

#bil-sum button:focus:not(:focus-visible) {
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1) !important;
  background: #ffffff !important;
  border-radius: 4px 0px 0px 4px;
}

/* Update By Vijay */
/* updated by jeyabalaji */
.all-flex-center-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 3px 7px 3px;
}
/* updated by jeyabalaji */
.bill-card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}

.bil-sum-head {
  color: #245C66 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

/* Update By Vijay  */
/* Update By saravana start  */

.tele_heal_filt_pop {
  display: flex;
  margin-top: 13px;
  /* margin-left: 21px;
  margin-right: -70px;
  margin-bottom: 80.5px; */
}
.tele_heal_filt_pop .card {
  /* width: 86% !important; */
  background: #ffffff;
  border: 1px solid #ebeaed;
  border-radius: 8px;
}
.tele_foot_sel {
  margin-bottom: 15px !important;
}
.title_card_1,
.title_card_2 {
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.tele_title {
  margin-left: -16px;
  margin-top: 7px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 10px;
}
.tele_hel_check_1 .ant-checkbox {
  margin-top: -18px !important;
}
.tele_hel_check .ant-checkbox {
  margin-top: -18px !important;
}
.tele_heal_filt_pop .card-body {
  overflow-x: hidden;
  /* padding-left: 20px !important; */
}

.tele_heal_filt_pop .card-header {
  background-color: #ffffff;
  text-align: left;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-right: 80px;
}

.tele_heal_filt_pop .card-header {
  background-color: #ffffff;
  text-align: left;
  border-bottom: none;
}
.tele_heal_pop_1 .card-header {
  background-color: #ffffff;
  text-align: left;
  border-bottom: none;
  color: #245C66;
}
.tele_head-1 {
  color: #245C66;
  font-size: 16px;
  font-weight: 500;
  margin-left: -2px;
}
.tele_heal_pop_1 {
  margin-right: 16px;
  margin-left: 20px;
}

.tele_heat_popup_2,
.tele_heal_pop_1 {
  display: flex;
  /* width: 50%; */
  /* margin-right: 5% !important; */
}
.tele_heat_popup_2 {
  margin: 0px 20px 0px 0px;
}

.tele_heat_pop_2 {
  margin-right: 20% !important;
}
.tele_heal_filt_pop .tele_heat_pop_2 {
  width: 50%;
  margin-right: 20% !important;
}

.tele_heat_pop_2 .card {
  height: 206px !important;
  width: 200%;
}
.setting_icon {
  transform: rotate(20deg);
}
.for_filter_button {
  position: relative;
}
.tele-down-icon {
  position: absolute;
  top: 9px;
  right: 10px;
}
.for_filter_button > .drop-down {
  border: 1px solid #cbd5e0 !important;
  border-radius: 10px !important ;
  background-color: #f8f9fa;
  width: 120% !important;
}
#dropdown-basic_tele {
  margin-left: 6% !important;
  width: 113px;
  border: 1px solid #cbd5e0;
  border-radius: 5px !important;
  background-color: #ffff !important;
  height: 34px !important;
}
.tele_calen .ant-picker {
  height: 34px !important;
  /* margin-left: -5% !important; */
}
.tele-select_form .form-control {
  padding-left: 8% !important;
  /* padding-top:2% !important; */
  height: 34px !important;
  margin-left: -7% !important;
}
.bg-back_tele {
  background-color: #f7f7f7;
  margin-bottom: 20px;
}

.tele_drop_down_list {
  float: left !important;
  margin-left: -464px !important;
  box-shadow: 0 2px 10px rgb(0 0 0 / 17%);
  border-radius: 10px;
  border: 1px solid #cbd5e0 !important;
  margin-top: 36px !important;
  transform: none !important;
}
.tele_drop_down_list {
  inset: 0px auto auto 50px !important;
  width: 686px;
}
.bill_summary_chart .recharts-surface {
  margin-top: -4%;
  /* margin-bottom: 6%; */
  width: 79%;
  margin-left: -7%;
  margin-right: 21%;
}
.bill_summary_chart > .card-body {
  height: 20% !important;
  width: 41% !important;
}
*/ .bill_summary_chart,
.recharts-layer text {
  fill: #2d3748 !important;
  font-size: 18px;
}

.tele_health_filter .modal-dialog {
  float: right;
  max-width: 763px;
  margin-top: 200px !important;
  margin-right: 234px;
  transition: revert;
}
.tele_health_filter > .fade.modal-dialog {
  transition: revert;
}

.tele_health_filter .modal {
  margin-bottom: 400px !important;
}

.tele_health_filter .modal-content {
  width: 133% !important;
  background-color: #ffffff;
  border: none !important;
}
.tele_heat_popup_3 .card {
  height: 206px !important;
  width: 113% !important;
  margin-left: -23%;
}
.fil_txt {
  color: #245C66;
}
.tele_heal_pop_2 {
  width: 100% !important;
  margin-left: 5% !important;
}
.tele_heat_popup_2 .card {
  width: 100% !important;
}
.tele_heat_popup_2 .card-header {
  border-bottom: none;
}

.tele_heat_popup_3 .card-body {
  padding: none !important;
  border: none !important;
  margin-left: -12%;
  font-size: 16px;
  font-weight: 500;
  margin-top: -10% !important;
  height: 206px;
}
.tele_heat_popup_2 .card-body {
  padding: none !important;
  border: none !important;
  font-size: 16px;
  font-weight: 500;
}
.tele-filter-box1 {
  display: flex;
  align-items: center;
}
.filter_dropdown_btn {
  border-radius: 15px;
  position: absolute;
  margin-top: -38px;
  bottom: auto;
  transform: none;
}
/* .bill_sum_table {
  Margin-Top:-38%  !important;
} */
.bill_sum_head_table {
  display: flex;
  margin-left: 130% !important;
}
.bill_head {
  float: left !important;
  color: #245C66;
}
.bill_sum_head_table .MuiButton-root {
  min-width: 116px !important;
  margin-left: -141px;
}
.m-l---15 {
  margin-left: 1%;
  width: 10%;
  background: #dddcdc;
  border: 1px solid #cbd5e0;
}
/* .bill_summary_chart .recharts-rectangle .recharts-tooltip-cursor{
  
} */
.bill_summ_card .card {
  margin-left: 2% !important;
  margin-right: 5% !important;
  height: 5% !important;
  width: 96% !important;
  margin-bottom: 3% !important;
}

.bill_summ_card .card-body {
  margin-left: 2% !important;
  margin-right: 5% !important;
  height: 5% !important;
  /* width:97% !important */
}
.day_drop .form-control {
  max-width: 102% !important;
}
.daysheet_card .card {
  margin-top: 2% !important;
  height: 500px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  padding: none !important;
}
.daysheet_card .card-header {
  background-color: #fff;
  margin-bottom: 2% !important;
  padding: 10px 0px 0px 0px !important;
  border-bottom: none !important;
}
.serviceq_sheet {
  padding-left: 22px;
  padding-top: 5px;
  color: #718096;
  font-weight: 400;
}

.bill_summary_chart .recharts-default-tooltip {
  float: left !important;
  font-family: DM Sans, sans-serif;
  height: 37px;
  margin-top: 20% !important;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  border-radius: 7px;
}
.bg_back_report {
  background-color: #fcfcfc;
  padding-top: 12px;
}
.remittance_card .card-header {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
  margin-bottom: 2% !important;
  padding: 10px 0px 0px 0px !important;
  border-bottom: none !important;
}
.remitt_table_head {
  padding-left: 2%;
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}
.remiitt_head_fmt_ {
  float: right !important;
  margin-right: 2%;
}
.ins-cl-ex_b_l {
  height: 32px;
  color: #245C66;
}
.Outgng_presp_search .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
  max-width: 94% !important;
}
.Outgng_prep_search_1 .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
  max-width: 94% !important;
}
.Outgng_prep_search_2 .ui.icon.input > input {
  background-color: #fff !important;
  height: 32px !important;
  max-width: 94% !important;
}
.Unmatched_era_card .card {
  margin-top: 2% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}

.unmatched_era_head {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}

.Unmatched_era_card .card-header {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  border-bottom: none !important;
}
/* .ins-p-s .ant-input, .ins-p-s .ant-input-search-button, .ins-p-s .ant-picker  */
.outgng_presp_exprt_btn {
  margin-left: 1% !important;
}
.outgng_presp_card .card {
  margin-top: 2% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}
.outgng_pres_head {
  color: #245C66;
  font-size: 20px;
  font-weight: 500;
  float: left !important;
}
.outgng_presp_card .card-header {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  margin-bottom: -2% !important;
  padding-top: 1%;
  border-bottom: none !important;
}
.app_stat_btn {
  padding-left: 2% !important;
}
.vitals-btn_a_r {
  border: 1px solid #cbd5e0;

  width: 93px !important;
  height: 35px !important;
}
.appoint_report_card {
  margin-top: 24px !important;
  margin-left: -6px !important  ;
}
.appoint_report_card .card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}
.appoint_report_head {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}
.appoint_report_card .card-header {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  border-bottom: none !important;
}
.remind_report_card .card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}
.remind_report_head {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}
.remind_report_card .card-header {
  color: #245C66;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  padding-top: 2%;
  border-bottom: none !important;
}
.out_presp_table {
  float: right;
  border: 1px solid #ebeaed;
  background: #ffffff !important;
}
.bg-back_report___ {
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
}
.tele_calen .ant-picker-input > input {
  padding-left: 18% !important;
  /* margin-top:-3% !important; */
}
.tele_calen .ant-picker-input > input::placeholder {
  color: black !important;
}
.tele_calen .ant-picker-suffix {
  color: black;
  margin-top: -2px;
}
.tele_calen .ant-picker-separator {
  padding-left: 2% !important;
  /* margin-left: 25% !important; */
  display: inline-block;
  margin-top: -39% !important;
  color: black;
}

.prob-top-layer_outgng,
.prob-top-layer_unmatch {
  display: flex;
  padding-left: 16px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.unmat_table {
  margin-top: 0px !important;
}
.icon-border_email {
  background-color: rgba(255, 94, 69, 0.1) !important;
  border-radius: 40px;
  padding-top: 0px;
  width: 29px;
  height: 26px;
  /* margin-top: -1px; */
}
.bg_back_remind {
  background-color: #fcfcfc;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.appt-rep-bg-back {
  background-color: #f7f7f7;
  padding-top: 17px;
  padding-bottom: 16px;
}
.grnd-tot-chart canvas {
  height: 100% !important;
}

.grnd-tot-chart {
  border: 1px solid #ebeaed;
  border-radius: 6px;
  margin-top: 30px;
  height: 267px;
  padding: 0px 80px 0px 1px;
  width: 550px;
  margin-left: 40px;
  margin-bottom: 20px !important;
}
.grd-tot-card-symbol1 {
  background-color: #208241;
}
.grd-tot-card-symbol1,
.grd-tot-card-symbol2 {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: auto;
  /* margin-right: auto; */
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-top: 6px;
}
.grd-tot-card-symbol2 {
  background-color: #B91414;
}
.day-sheet-rep-btn {
  height: 34px !important;
  margin-left: 20px;
}
.day-sheet-rep-btn .form-control {
  height: 34px !important;
  border: 1px solid #cbd5e0;
}
.out-prsc-dt .ant-picker-input {
  color: black;
}
.day-sheet-rep-btn .ant-picker {
  height: 34px !important;
  width: 140px !important;
  border: 1px solid #cbd5e0;
  color: black !important;
}

.day-sheet-rep-btn .ui.icon.input {
  height: 34px !important;
  width: 155px;
}
.day-sheet-rep-btn .ui.icon.input input {
  background-color: white !important;
  border: 1px solid #cbd5e0;
}
.mid-text-align {
  height: 34px !important;
  display: flex;
  align-items: center;
}
.rep-day-sht-btn {
  margin-left: 20px;
}
.rep-day-sht-btn .btn.btn-primary {
  height: 33px !important;
}
.rep-day-sht-btn .form-control {
  height: 34px !important;
}
.rep-tab-container {
  border: 1px solid #e2e8ed;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.day-sheet-rep-btn .ant-picker-suffix,
.appoin_report_date_picker .ant-picker-suffix {
  margin-bottom: 3px !important;
  color: black !important;
}
.rep-select-wrapper .form-control {
  -webkit-appearance: none;
  appearance: none;
  color: black !important;
  border: 1px solid #cbd5e0;
}
.rep-select-wrapper {
  position: relative;
}
.rep-select-wrapper::after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 10px;
  top: 9px;
  right: 10px;
  position: absolute;
}
.all-flex-center-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 3px 4px 1px;
}
.rep-flex-align-center {
  display: flex;
  align-items: center;
}

.rep-check-wrapper .ant-checkbox-wrapper {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.rep-scnd-layer {
  background-color: #f7f7f7;
  padding: 0px 20px 20px;
  flex-wrap: wrap;
}
.daysheet-snd-layer {
  background-color: #f7f7f7;
  padding: 0px 0px 16px;
  flex-wrap: wrap;
}
.day-sheet-rep-btn .ant-picker-input > input::placeholder,
.appoin_report_date_picker .ant-picker-input > input::placeholder {
  color: black !important;
}
.tele-update-btn .btn.btn-primary {
  height: 34px !important;
  width: 126px;
}
.tele-select-btn .form-control {
  height: 34px !important;
  width: 155px;
  border: 1px solid #cbd5e0;
}
/* updated by jeyabalaji */
.tele-search-btn .ui.icon.input {
  width: 190px;
  margin-right: 20px;
  height: 34px;
  border-radius: 4px;
  background-color: white;
}
/* updated by jeyabalaji */
.tele-drop-btn {
  border: 1px solid #cbd5e0;
  height: 34px !important;
}
.tele-br-null1 {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.tele-br-null2 {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
/* Update By saravana end  */
/* Update By jeyabalaji */
.tele-search-btn ::placeholder {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}

.tele-select-btn select,
.antd-dd-tele_repo {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.tele_calen input {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}

.out-presc-search ::placeholder {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.appoin_report_date_picker input {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.appoint_report_search ::placeholder {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.appoin_report_form select,
.appoin_report_form .antd-dd-apt_repo {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
}
.for-rem-rep-dm-yw {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
  justify-content: center;
}
.for-app-rep-tble-head tr:last-child {
  border-bottom: none !important;
}
.for-rem-rep-tble-hd-tit tr:last-child {
  border-bottom: none !important;
}
.for-bill-summary-tbl-head tr:last-child {
  border-bottom: none !important;
}
.for-tele-repo-tbl-hd tr:last-child {
  border-bottom: none !important;
}
.for-out-pres-tbl-head tr:last-child {
  border-bottom: none !important;
}
.credit_table_head tr:last-child {
  border-bottom: none !important;
}
.for-day-sht-pat-pay tr:last-child {
  border-bottom: none !important;
}
.for-day-sht-charge-tble tr:nth-last-child(2) {
  border-bottom: none !important;
}
.rep-flex-align-center ::placeholder {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
  justify-content: center;
}
.rep-flex-align-center input {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
  justify-content: center;
}
.rep-flex-align-center select,
.rep-flex-align-center .antd-dd-pay_anly,
.for-rem-rep-res-srch-box-sty-fd .ant-picker-input > input {
  font-family: DM Sans , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #242222;
  justify-content: center;
}
.for-day-sheet-full-page-hgt {
  height: 980px !important;
}
/* Update By jeyabalaji */

/* Update By saravana start  */

.tele-fil-part-1,
.tele-fil-part-2 {
  display: flex;
  align-items: center;
}
.tele-fil-part-1 {
  width: 400px;
  padding-left: 10px;
}
.position-fil-2 {
  padding-top: 3px;
  padding-left: 0px;
  margin-left: 90px;
  margin-top: -15%;
  text-align: left;
  margin-bottom: 10px;
}
.fil-part-col3 {
  width: 300px;
}
.fil-part-col2 {
  padding-left: 20px;
  margin-left: 34px;
}
.all-flex-center-box1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 2px 16px 2px;
}
.pat-rep-back-full {
  background-color: white;
  padding-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  color: #2d3748;
}
.for-pat-rep-res-fdate .ant-picker-input > input::placeholder,
.for-pat-rep-res-fdate .ant-picker-input .ant-picker-suffix {
  color: black !important;
}

/* Update By saravana end  */
/* ---------------------------------------Vanitha---------------------------------- */

.modif {
  padding: 0px !important;
  margin-top: 9px !important;
  margin-bottom: 10px !important;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: DM Sans, sans-serif;
}
.modif_left {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #245C66;
  float: left !important;
}
.title_all {
  font-family: DM Sans, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 28px !important;
  line-height: 33px !important;
  color: #2e2c34 !important;
}
.bot {
  padding-bottom: 0px !important;
}
.modremind {
  padding: 0px !important;
  /* border: 1px solid; */
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  font-family: DM Sans, sans-serif !important;
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 600 !important;
  padding-left: 20px !important;
}
.pro_rep_sec_row {
  background-color: #f7f7f7 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.prop_rep_thi_row_main {
  border: 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.prop_rep_thi_row {
  /* border: 1px solid; */
  height: 100px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding-left: 20px !important;
}
.prop_rep_thi_row > div {
  margin-right: 20px;
}
.prod_file {
  margin-left: auto;
  margin-right: 108px;
  /* margin-bottom: 20px; */
  margin-top: auto;
}
.alt {
  margin-bottom: 4px !important;
}
.rem_rep_icon {
  margin-top: 0px !important;
}
/* ---------------------------------------Vanitha------------------------------------------------------ */
/* ---------------------VANTIHA----------------- 16-02-22---------------------*/

.user_third_rowall {
  /* border: 1px solid; */
  background-color: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 20px;
}
.user_thir_row {
  padding-top: 20px;
  padding-bottom: 20px;
  /* border: 1px solid; */
  text-align: center;
}
.modif1 {
  padding: 0px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: DM Sans, sans-serif;
}
.modif1_left {
  font-style: DM Sans , sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #245C66;
  float: left !important;
  margin-left: 20px;
}

.modif_user {
  padding: 0px !important;
  /* border: 1px solid black; */
  align-items: center;
  justify-content: space-between;
  font-family: DM Sans, sans-serif;
  border: 1px solid #ebeaed;
  border-bottom: none;
}
.modif_user_left {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #245C66;
  float: left !important;
  /* border: 1px solid; */

  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
}
.background {
  background-color: #f7f7f7;
  margin-top: 0px !important;
}
.user_bt {
  height: 33px !important;
  margin-left: 42px;
  margin-top: 2px;
}

.whole_tab {
  /* border: 1px solid; */
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
}
.inner_tab {
  border: 1px solid #cbd5e0;
}
.elect_data_clr {
  font-family: DM Sans, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */
  color: #245C66 !important;
}
.fav_pres_top1 {
  display: flex !important;
  margin-bottom: 20px !important;
  justify-content: space-between;
  width: 100% !important;
  margin-top: 20px !important;
  font-family: DM Sans, sans-serif;
}
.fav_pres_tab_bot {
  margin-bottom: 22px !important;
}

.lab_alt {
  margin: 0px;
  padding: 0px;
  /* border: 1px solid black; */
  padding-right: 20px;
}

.payer_sec_row_main {
  background-color: #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
  /* border: 1px solid; */
  display: flex;
}

.payer_btn {
  margin-left: auto;
  margin-right: 20px;
}

.user_rep_sec_row {
  background-color: #f7f7f7;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.check_active {
  display: flex;
  margin-top: -9px;
}
.user_btn {
  font-weight: 500;
  margin-left: 22px;
  margin-top: 4px;
  background-color: #245C66;
}

.inv_line {
  line-height: 16px !important;
}

.appt-block-main-bill .inv_line input {
  padding-top: 9px !important;
}
.appt-block-main-bill .ant-picker span {
  display: grid;
  place-content: center;
}
.appt-block-main-bill .user_rep_sec_row .ui.icon.input input {
  padding-top: 8px !important;
}
/* -----------------------VANITHA----------------------End------------ */
/*cheran*/
.fnt-clr {
  color: #000000 !important;
}
/*cheran*/

/* --------------------------------Updated by Vanitha--------Start------------------ */
.prodtable_alt {
  /* border: 1px solid; */
  padding: 0 20px 20px 20px !important;
}

.wholetab {
  border: 1px solid #ebeaed;
}
.prodtab_top {
  display: flex !important;
  margin: 20px;
}

.Prodtab_lef {
  color: #245C66;
  font-size: 18px !important;
  font-weight: 600 !important;
  float: left !important;
}
.Prodtab_rig {
  margin-left: auto !important;
}

/* --------------------------------Updated by Vanitha---------end----------------- */

#demographics
  > div
  > div
  > div.bg-back_report.m-b-20.p-t-16.pat-rep-srch-box
  > div.d-flex.p-r-20.pat-rep-box-1
  > div.patr-m-t-12.for-pat-rep-res-ethn
  > div
  > div {
  margin-left: 20px !important;
  width: 100% !important;
}

.pat_fm_date_alt input {
  width: auto !important;
  margin-right: 10px !important;
}

.pat_rep_fil_in_alt input {
  margin-left: 56px !important;
  margin-right: 20px !important;
}

.pat_to_date_alt {
  margin-left: 24px !important;
}

.for-pat-rep-res-sex_alt {
  margin-left: 67px !important;
}

.pat_fm_date_alt input[type="date"]:before {
  content: attr(placeholder) !important;
  margin-right: 0.3em;
  /* width: auto; */
}
.pat_fm_date_alt input[type="date"]:focus:before {
  content: attr(placeholder) !important;
}

#demographics
  > div
  > div
  > div.bg-back_report.m-b-20.p-t-16.pat-rep-srch-box
  > div.d-flex.p-r-20.pat-rep-box-1
  > div.patr-m-t-12.for-pat-rep-res-sex
  > div
  > div {
  margin-left: 20px !important;
  width: 100% !important;
}

#demographics
  > div
  > div
  > div.bg-back_report.m-b-20.p-t-16.pat-rep-srch-box
  > div.d-flex.p-r-20.pat-rep-box-1
  > div.patr-m-t-12.for-pat-rep-res-race
  > div
  > div {
  margin-left: 20px !important;
  width: 100% !important;
}

.for-pat-rep-res-race_alt {
  width: 17% !important;
}

.fdate_alt {
  margin-right: 5px;
}

.appoint_report_card .card {
  margin-left: 26px !important;
  margin-right: 22px !important;
}
.bill-hover #btn-bil {
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1) !important;
  background: #245C66 !important;
  color:#fff;
  border-radius: 4px 0px 0px 4px;
}

#billSummaryCard {
  margin-left: 22px !important;
  margin-bottom: -4px !important;
  margin-top: 0px !important;
}
#billSummaryCard1 {
  margin-left: 28px !important;
  margin-bottom: 13px !important;
}

/* .bill-hover #btn-bil {
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1) !important;
  background: #ffffff !important;
  border-radius: 4px 0px 0px 4px;
} */

.remindReportTableBody tr:last-child {
  border-bottom: none !important;
}
.remindTableloadmore {
  padding-bottom: 20px !important;
  padding-top: 0px !important;
}

.load_more_alt1 {
  padding-top: 15px !important;
  padding-bottom: 20px !important;
}
.appt_tab_alt1 tr:last-child {
  border-bottom: none !important;
}
.appt_pat_alt .search {
  padding-bottom: 5px !important;
}
.apprep_tab_rad_alt table {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.invoiceTableLoadMore {
  padding-top: 20px !important;
  padding-bottom: 0px !important;
  margin-bottom: -1px;
}
.ivoicetablePaid {
  padding-bottom: 6px !important;
}
.ivoicetableUnPaid {
  padding-bottom: 6px !important;
  background-color: #fad8d8 !important;
  /* color: #ffffff; */
  color: rgb(182, 36, 36);
}
.ivoicetablePartialPaid {
  padding-bottom: 6px !important;
  background-color: #fcf9e1 !important;
  color: #ebc802;
}

.load_more_alt1 {
  padding-top: 15px !important;
  padding-bottom: 20px !important;
}
.appt_tab_alt1 tr:last-child {
  border-bottom: none !important;
}
/* Vanitha 29-04-22 */

/* Vanitha 30-04-22 */

.appt_pat_alt .search {
  padding-bottom: 5px !important;
}
.apprep_tab_rad_alt {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
/* Vanitha 30-04-22 */

/* Vanitha 02-04-22 */

/* Vanitha 05-05-22              (this cls was already given, but now updated..)*/
.appt_btn_alt button:focus,
.appt_btn_alt button:active,
.appt_btn_alt button:hover {
  background-color: #16424A !important;
  color: white !important;
}

.rep-outg-search .results.transition.visible {
  overflow: auto !important;
  max-height: 250px !important;
}
.outg-pres-mar-lr {
  margin-left: -5px !important;
  margin-right: -1px !important;
  margin-bottom: -2px !important;
}
.out-prs-brdr-clr .card {
  border: 1px solid #cbd5e0 !important;
}
.all-for-marg-t-4 {
  margin-top: 4px;
}
.prd-rep-clock-style {
  font-size: 40px;
}
.pr-rep-mar-h {
  height: 38px !important;
  margin-top: 3px;
}
.pro-rep-mr-wid {
  margin-left: 15px;
  width: 100%;
}
.pro-rep-drop {
  margin-left: 16px;
  width: 15%;
  min-width: 165px !important;
}
.pro-rep-dr-sty {
  height: 38px;
  width: 100%;
}
/*----------update by cheran 27-4-22 Start-----------*/
.ds-padnil {
  color: #000000 !important;
}
.ds-flex {
  display: flex !important;
  padding: 20px 0px 4px 20px !important;
  width: 300px !important;
}
.report-lmb {
  padding: 20px 0px 0px 0px !important;
}
.coll-tbhead-bdtop {
  border-top: none !important;
  border-bottom: 1px solid #cbd5e0 !important;
}
.coll-tbody-bbtm {
  border-bottom: 1px solid #cbd5e0 !important;
}

.pa-repo-con {
  margin-top: 2.5px !important;
  padding-top: 7px !important;
}
.box-pa-report {
  border-radius: 6px !important;
}
.Ds-pad {
  padding: 20px 0px 20px 0px !important;
}
.ds-pb {
  padding-bottom: 20px !important;
}
.Ds-margb {
  margin-bottom: 0px !important;
}
/*----------update by cheran 27-4-22 End-----------*/
/*-----------------Update by cheran 3-5-22 Start---------------*/
.TH-lmb {
  padding-top: 9px !important;
  padding-bottom: 11px !important;
}
.userreport-lmb {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.telbtn-c {
  background-color: #dae0e5 !important;
  border-color: #d3d9df !important;
}
/* Ahamed_update_start_29.04.22 */
.loading-more-pro-proc > #load-data {
  margin: 0 auto;
}
.results.transition {
  max-height: 355px;
  overflow-y: auto;
}
.none-top-border {
  border-top: none !important;
}
.bg-report-back {
  margin-top: 0px;
  background-color: #f7f7f7 !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.bg-report-back > .sec-row-ins {
  margin-bottom: 0px !important;
}

.bg-report-back .pro-pdr-appt input {
  height: 32px !important;
  background-color: #fff !important;
}
/* Ahamed_update_end_29.04.22 */
/*-----------------Update by cheran 3-5-22 End---------------*/
/*-----------------Update by cheran 5-5-22 Start---------------*/
.sec-row-fam.collapse.show > td > table > tbody > tr:last-child {
  border-bottom: none !important;
}
.rp-head-title {
  padding-top: 0px !important;
}
.tl-mt-4 {
  margin-top: 4px !important;
}
.tele-title-th {
  padding: 8px 3px 7px 2px !important;
}
/*-----------------Update by cheran 5-5-22 End---------------*/
.appt_default_alt {
  background-color: #245C66 !important;
  color: white !important;
  border-color: #245C66 !important;
}

.appt_default_alt:hover{
  border-color: #245C66 !important;
}

.pat_rep_sea_wid_alt {
  width: 246px !important;
}
/* Vanitha 05-05-22 */
.prd-rep-clock-style {
  font-size: 40px;
}
.pr-r-calender-style {
  font-size: 40px;
  margin-right: 10px;
}
.pr-rep-btn-ecp {
  height: 32.5px;
  font-weight: 500;
}
.pr-rep-mt-5 {
  display: flex;
  align-items: center;
}
.pr-rep-mt-5 .ant-checkbox-wrapper {
  margin-top: 0px !important;
}
.pr-rep-mar-h {
  height: 38px !important;
  width: 100px;
}
.pro-rep-mr-wid {
  margin-left: 15px;
  width: 100%;
}
.pro-rep-dr-sty {
  height: 38px;
  width: 100%;
}
.dt-err-mess {
  position: absolute;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn {
  background-color: #245C66 !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn span {
  color: white !important;
}

.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn1 {
  background-color: #245C66 !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn1 span {
  color: white !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn2 {
  background-color: #245C66 !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn2 span {
  color: white !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn3 {
  background-color: #245C66 !important;
}
.for-rem-rep-res-srch-box-sty-d-m-y .wrt #reminderReportBtn3 span {
  color: white !important;
}
/* #remindrepoButton .btn-light:hover {
  background-color: #245C66 !important;
} */

/* #remindrepoButton .btn-light:focus:not(:focus-visible) {
  background-color: #245C66 !important;
  color: white !important;
}
#remindrepoButton .btn-light span:hover {
  color: white !important;
} */
/* #remindrepoButton .btn-light:active {
  background-color: #245C66 !important;
  color: white !important;
}
#remindrepoButton .btn-light span:active {
  color: white !important;
} */

.invoiceSmallHeading {
  font-weight: 600 !important;
}
/* remindrepoButton */
/*Ahamed_update_07-05-2022*/
#prod-pros-tab .prompt {
  background-color: #fff !important;
}
#prod-pros-tab i.icon:before {
  top: 25%;
}
/*Ahamed_update_07-04-2022*/

/* Vanitha 09-05-22 */
.appt_rep_check_lab_alt {
  margin-top: 1px !important;
  margin-bottom: -14px !important;
}

.appt_rep_agefilt_alt {
  margin-right: 68px !important;
}

.appt_rep_racefilt_alt {
  margin-right: 12px !important;
}

.appt_rep_genfilt_alt {
  margin-left: 0px !important;
}

.pat_rep_top_filt_bor_alt {
  margin-bottom: 16px !important;
}

.pat_rep_top_secfilt_bor_alt {
  padding-bottom: 14px !important;
  margin-top: -2px !important;
}

.pat_rep_tab_bot_alt {
  padding-bottom: 10px !important;
}

/* tab_title_main */
/* Vanitha 09-05-22 */
.pro_rep_sec_row
  div:not(
    .service--007,
    .pro-rep-drop,
    .ant-picker-input,
    .ant-picker,
    .ant-picker-range-separator
  ) {
  margin-left: 16px;
  margin-right: 20px !important;
}
.pro_rep_sec_row div.ant-picker-range-separator {
  margin-right: 16px !important;
}
.pro_rep_sec_row select,
.pro_rep_sec_row .ant-picker,
.antd-dd-pro_repo .ant-select-selector {
  height: 34px !important;
}
.pro_rep_sec_row .btn.btn-primary {
  height: 32.5px !important;
}
.out-pres-tick-ic {
  background-color: green;
  color: white;
  margin-right: 16px;
}
.out-pres-dang-ic {
  background-color: red;
  color: white;
  margin-right: 16px;
}
.out-pres-hgt-wdh {
  height: 20px;
  width: 20px;
}
.outg-pres-mar-lr .card-header {
  padding: 20px !important;
}
.out-pres-exp-btn.btn.btn-success {
  font-weight: 400;
  height: 32px !important;
}
.eob-mg-bt-10 .thead {
  margin-bottom: -6px !important;
}

/* Vanitha 10-05-22 */

.pat_rep_tab_dos_alt {
  width: 118px !important;
}

.pat_rep_fil_medalleprob_alt {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #2e2e2e !important;
}

.pat_rep_top_secfilt_bor_alt1 {
  margin-top: 16px !important;
  padding-bottom: 16px !important;
}

.appt_rep_checklab_alt {
  margin-top: 8px !important;
}

.appt_rep_sel_ofc_alt {
  font-size: 14px !important;
}
/* Vanitha 10-05-22 */

/* Vanitha 11-05-22 */
.pat_rep_top_filt_bor_alt1 .form-group {
  margin-bottom: 0px !important;
}
/* Vanitha 11-05-22 */
/*Ahamed_update_07-05-2022*/
/*Ahamed_12-05-2022*/
#prod-proc-main .product_proce_table {
  margin-top: 20px !important;
}
#prod-proc-main .Pat_statement_table_sublist {
  font-weight: 600 !important;
}
.clr-pry-blu {
  color: #245C66 !important;
}
#prod-pros-tab .prob-head-title > a > svg {
  margin-top: -3px;
  margin-right: 5px;
  color: #245C66 !important;
}
#prod-pros-tab .prob-head-title.m-t-0 {
  margin-top: 2px;
}
.product_proce_table {
  margin-top: 20px !important;
}

/*Ahamed_12-05-2022*/
/* Vanitha 13-05-22 */
.appt_rep_chk_unchk_alt {
  border-radius: 4px !important;
  border-color: #245C66 !important;
}

.appt_rep_pay_status_alt {
  padding: 4px 13px 3px 18px !important;
}

.col-md-3 .mr-tp-12 .ant-checkbox-wrapper {
  margin-right: 0px !important;
}

.col-md-4 .mr-tp-12 .ant-checkbox-wrapper {
  margin-right: 0px !important;
}

.pat_rep_tab_dos_alt1 {
  width: 124px !important;
}

/* Vanitha 13-05-22 */

/* Vanitha 15-05-22 */

.pat_rep_load_morealt {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

/* Vanitha 15-05-22 */
/*-----------------update by Cheran Start -----------------------*/
.telbtn-c1 {
  background-color: #245C66 !important;
  color: #fff !important;
  font-family: DM Sans, sans-serif;
}

.for-fl-pad-btm .for_dot_act_bgc1 {
  position: relative !important;
  /* background-color: lightgreen !important; */
  background-color: #c9f7e6 !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px !important;
  overflow-y: hidden;
}
/* */
.for-fl-pad-btm .for_dot_act_bgc2 {
  background-color: #ffe5e3 !important;
  position: relative !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px !important;
  overflow-y: hidden;
}

.dot-act_inactive_alt-c {
  position: absolute;
  top: 6px !important;
  left: 4px;
}
.dot-active-c {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -2px;
  width: 8px;
  background-color: #57bf7f;
  margin-top: -2px;
}
.dot-inactive-c {
  border-radius: 50%;
  margin-top: -2px;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -2px;
  width: 8px;
  background-color: #d43731;
}
.valid-clse-btn .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #f6f7f9 !important;
  color: #2e2e2e !important;
}
.collapse-c-head-color .MuiTableRow-root.MuiTableRow-head th {
  color: #2e2e2e !important;
}
.for-fl-pad-btm .ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #d9d9d9 !important;
}
.ant-picker:hover{
  border: 1px solid #245C66 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background-color: #245C66 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid #245C66 !important;
}

.ant-picker-header-view button:hover{
  color: #245C66 !important;
}

.ant-select-multiple .ant-select-selection-item{
  color: #245C66 !important;
}
:where(.ant-select-multiple .ant-select-selection-item-remove) {
  color: #245C66 !important;
}

/*-----------------update by Cheran Start -----------------------*/
/*Ahamed_13-05-2022*/
#prod-pros-tab .bg-report-back {
  padding-bottom: 20px !important;
}
/*Ahamed_13-05-2022*/
/*-----------------update by Cheran Start -----------------------*/

.dsheet-pat-search .ui.icon.input,
.dtpk-appt_repo.appoin_report_date_picker .ant-picker {
  width: 173px !important;
}
.dsheet-pat-search .ui.icon.input input {
  padding-right: 4px !important;
}
.prt-mgs {
  margin-top: 16px !important;
  margin-bottom: 4px !important;
  margin-left: 0px !important;
}
.pat_rep_fil_in_alt .ant-input,
.antd-dd-pat_repo .ant-select-selector {
  height: 34px !important;
}
.pat_rep_fil_in_alt .ant-input:hover,
.pat_rep_fil_in_alt .ant-input:focus {
  border: 1px solid #ced4da;
  box-shadow: none !important;
}
.thr-btn-bdr {
  border-radius: 4px !important;
}
.thr-picker .ant-picker {
  width: 400px;
}
.prt-wrap-adj {
  height: 33px !important;
}
.prt-mgr-race {
  margin-right: 20px !important;
}
.tele-search-btn.ui.input.focus > input,
.ui.input > input:focus {
  border: 1px solid #ced4da !important;
}
/*-----------------update by Cheran End -----------------------*/

/*Ahamed_Changes 17-07-22*/
.pat_fm_date_alt {
  padding-left: 0;
  margin-bottom: -5px !important;
}
.age_sector {
  margin-left: 17px !important;
  margin-right: 20px !important;
  margin-top: 9px;
}
.gender_selector {
  margin-top: 9px;
  margin-right: 20px !important;
}
.age_sector input,
.gender_selector .for-pat-rep-res-sex_alt {
  margin-left: 0px !important;
}
/* .age_sector header */
.age_sector .for-pat-rep-res-age {
  margin-left: 5px !important;
  width: 50px !important;
}
/* .race_sector .pat_rep_sea_wid_alt {
  width: 0px !important;
} */
.ethnicity_sector .pat_rep_sea_wid_alt {
  width: 200px !important;
}
.ethnicity_sector {
  margin-left: 8px;
}
.patr-m-t-12 {
  margin-top: 8px !important;
}

.gender_selector .for-pat-rep-res-sex_alt {
  width: 104px !important;
}

.tele-vis-stat .ant-checkbox-wrapper {
  margin-left: 90px !important;
}

.tele-appt-stat .ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.tele-dt-pic .ant-picker-input > input {
  padding-left: 8px !important;
}
.appt-appt-stat .ant-checkbox-wrapper,
.appt-bil-stat .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

/*Ahamed_Changes 17-07-22*/
/* Vanitha 15-06-22 */

.prodtable_alt div {
  /* border-radius: 6px !important; */
  overflow-x: hidden !important;
}

/* Vanitha 15-06-22 */

/* ------------------------preetha---------------------- */

.remittance_table_export {
  width: 120px;
  height: 34px;
  margin-right: 23px;
  margin-left: 18px;
  border-radius: 4px;
}

.remittance_table_print {
  background-color: #fafafa;
  border: 1px solid #ebeaed;
  color: #718096;
  width: 81px;
  height: 34px;
  border-radius: 4px;
}

.remittance_table_add_eob {
  background-color: white;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  width: 109px;
  height: 34px;
}

.rem-btn {
  width: 96px !important;
  height: 34px !important;
}

.rpt-rep-res-srch .ui.icon.input {
  width: 157px !important;
  height: 34px !important;
  background-color: #fff !important;
}

.text-align {
  width: 61px !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
  color: #718096 !important;
  font-family: "DM Sans , sans-serif";
  padding-bottom: 6px;
  padding-top: 22px;
}

.rem-date {
  width: 126px !important;
}

.bg-back_remit {
  background-color: #f7f7f7;
  padding-bottom: 17px;
  padding-right: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  display: flex !important;
}

.MuiTableRow-root:last-child {
  border-bottom: none !important;
}

.errormessage_1 {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  position: absolute;
  text-align: left;
}

/* ------------------------preetha---------------------- */
.bar-chart-algn #billSummaryCard {
  margin-left: 20px !important;
  padding-left: 16px !important;
}

/* Vanitha  13-10-22*/
.credit_adjust_table_Alt {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
}

.appt_rep_card_table .card {
  margin-right: 20px !important;
}

.pat_rep_card_table {
  margin-right: -2px !important;
  margin-bottom: -11px !important;
}

.appt_rep_table_alt .card {
  margin-right: 20px !important;
}

.prod_rep_table_alt {
  margin-bottom: -10px !important;
}

.out_pres_table_alt .card {
  margin-left: 25px !important;
  margin-right: 21px !important;
  margin-bottom: 12px !important;
}

.roles_table_alt {
  margin-bottom: 10px !important;
}

.form_library_table_alt {
  padding-bottom: 9px !important;
}
/* Vanitha  13-10-22*/

/* Vanitha */

.MuiTableContainer-root {
  margin-top: 0px !important;
}

.clinical_report .MuiTableRow-head th {
  color: #777984 !important;
  font-family: DM Sans, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
.clinical_report .MuiTableCell-body {
  font-family: DM Sans, sans-serif !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #000000;
}
.tablecontainer1 .dosdata {
  color: #245C66 !important;
  font-weight: 500 !important;
}

.tablecontainer1 .apptdos {
  padding-left: 24px !important;
}

.tablecontainer1 .appt_border {
  border-left: 1px solid #e2e8ed !important;
}

.client_rep_th tr {
  background: #f1f5f8 !important;
}

.clinic_err .startdate_err {
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  /* margin-top: -40px;
  margin-bottom: 26px; */
}

.clinical_report .MuiTableRow-head .head_report {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #245C66 !important;
  padding: 24px !important;
  font-family: " 'DM Sans', sans-serif !important";
  background-color: white !important;
  border-left: 1px solid #e2e8ed !important;
}

.clinical_report .MuiTableRow-head .head_report1 {
  border-left: none !important;
}

.doswidth {
  min-width: 160px !important;
}

/* Vanitha */
/* ------------------------dhilip start---------------------- */
.reminder_font {
  font-family: "DM Sans,sans-serif";
}
.reminder_name .name {
  font-weight: 600;
  font-size: 14px;
}
.reminder_name .iborder {
  border: 1px solid #e2e8ed;
  border-radius: 5px;
}
.reminder_name .for-rem-rep-res-srch-box-sty .input,
.for-rem-rep-res-srch-box-sty-fd .ant-picker {
  height: 34px !important;
  width: 100% !important;
}

.reminder_name .ui.icon.input > input {
  height: 34px !important;
}
.reminder_name
  .for-rem-rep-res-srch-box-sty-d-m-y
  .btn-rem-rep-d-m-y:first-child {
  border-left: 1px solid #dbdbdb !important;
  border-right: 1px solid #dbdbdb !important;
  width: 23% !important;
  /* border-radius: 5px !important; */
}
.reminder_name .for-rem-rep-res-srch-box-sty-d-m-y .btn-rem-rep-d-m-y {
  border-left: 0px solid #dbdbdb !important;
  border-right: 1px solid #dbdbdb !important;
  width: 23% !important;
  /* border-radius: 5px !important; */
}

.reminder_name_body .for-rem-rep-table-data-pname {
  color: #245C66 !important;
  font-weight: 500;
}

.success_bg span {
  font-family: "DM Sans, sans-serif";
  font-weight: 600;
  background: rgba(32, 201, 172, 0.1);
  padding: 2px 8px 4px;
  border-radius: 4px;
  color: #20c9ac;
}

.failure_bg span {
  font-family: "DM Sans, sans-serif";
  font-weight: 600;
  background: rgba(252, 52, 0, 0.1);
  padding: 2px 8px 4px;
  border-radius: 4px;
  color: #fc3400;
}
/* Billing Changes */
/* .appt_rep_card_Alt {
  width: 96% !important;
} */
/* Billing Changes */

#billSummaryCard1 td {
  padding-left: 20px;
}

/* Jeyabalaji 29-11-2022 */
.pat_rep_3_err .ui.icon.input > input {
  border: 1px solid red !important;
}
/* Jeyabalaji 29-11-2022 */
/*Ahamed_Changes 13.12.2020*/
#rpt_prd-sel_stDet .errormessage {
  width: auto;
  margin-left: 35px;
}
/*Ahamed_Changes 13.12.2020*/
.out-presc-search .errormessage {
  position: absolute;
  margin-top: 0;
}
/* Ramachandhiran Inline CSS Start */

.reportPadTB {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ReportInvStatus {
  height: 40px !important;
  width: 100%;
  padding-top: 4px;
  font-size: 14px;
  font-family:  DM Sans, sans-serif;
}
.reportPadMarL {
  padding-left: 21px;
  margin-left: -21px;
}
.ReportWidth20Per {
  width: 20%;
}
.reportMarginR24 {
  margin-right: 24px;
}
.reportPad-l-20 {
  padding-left: 20px;
}
.reportHeight {
  height: 32px;
}
.reportTableLRadius {
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
}
.reportPatName {
  margin-left: 12px;
  color: #2e2e2e;
  font-weight: 500;
}
.demoPamentBox {
  margin-top: 4px;
  padding-bottom: 10px;
}
.demoPaymentControl {
  width: 160px;
}
.analysisRow {
  margin: -2px 5px 0px 5px;
}
.CreditAppoinment {
  color: #245C66 !important;
}
.CreditRowAlign {
  text-align: center !important;
  font-weight: 500 !important;
}
.CreditRowBack {
  background-color: #f7f7f7 !important;
}
.creditColor {
  color: #2e2e2e !important;
}
.creditRow {
  color: #151515 !important;
  font-weight: 500 !important;
}
.AnalysisRow {
  background-color: #f7f7f7;
  color: #2e2e2e;
  width: 13%;
}
.AnalysisRow1 {
  background-color: #f7f7f7;
  color: #2e2e2e;
  width: 23%;
}
.AnalysisRow2 {
  background-color: #f7f7f7;
  color: #2e2e2e;
  width: 14%;
}
.AnalysisBackgr {
  background-color: #f7f7f7;
  color: #2e2e2e;
}
.ProductSearch {
  padding-left: 15px;
}
.product_Error {
  border: 1px solid red;
  border-radius: 5px;
}
.productRowCode {
  margin-left: 4px;
}
.productRowCode {
  margin-left: 4px;
}
.product_TOtal {
  margin-left: 4px;
  color: #2e2e2e;
  font-weight: 500;
}
.ramitanceError {
  border: 1px solid red;
  border-radius: 5px;
}
.ramitanceFT {
  margin: 8px 8px 0px 8px;
}
.ramitanceMtb {
  margin-left: 12px;
  margin-right: 12px;
}
.ramitanceInnertab {
  margin-top: 24px;
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.margin_Right {
  margin-right: 20px;
}
.AppPaddingLeft0 {
  padding-left: 0;
}
.appStatus {
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 0px;
}
.appNameWieght {
  color: #2e2e2e !important;
  font-weight: 550 !important;
}
.appTextColor {
  color: black !important;
}
.appCellCenter {
  text-align: center !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.appFT {
  margin: 22px 31px 0px 16px;
}
.remaiderFlexPadding {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.remainderFlexPad7 {
  display: flex;
  padding-top: 7px;
}
.remaiderPadL12 {
  padding-left: 12px;
}
.remainderBack {
  background-color: #ecfbff;
}
.remaiderError {
  border: 1px solid red;
  border-radius: 5px;
  width: 95%;
}
.remainderWidthSheet {
  width: 100%;
}
.remaiderCellOne {
  text-align: center !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.remainderDayBtn {
  color: #242222;
}
.remainderFlex {
  display: flex;
}
.patientICDControl {
  width: 200px;
  height: 32px !important;
  margin-top: 4px;
}
.patientSearchBtn {
  height: 31px;
  margin-bottom: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.patientId {
  margin-left: 6px !important;
  color: #151515 !important;
}
.patientVersion {
  text-align: left;
  margin-left: 20px;
}
.patientMyTableDiv {
  margin-top: -10px;
}
.reportDivblock {
  display: block;
}
.reportDivnone {
  display: none;
}
.reportRowName {
  color: #2e2e2e !important;
  font-weight: 600 !important;
}
.rePadLef5 {
  padding-left: 5px;
}
.rePadLef10 {
  padding-left: 10px;
}
.reportSettel {
  padding-left: 0px;
  margin-left: -30px;
}
.reportWrapBtom {
  flex-wrap: wrap;
  margin-bottom: 21px;
}
.reportEmptyDiv {
  padding-left: 8px;
  margin-top: -21px;
}
.reportExpBtn {
  height: 32;
  margin-left: 20px;
  font-weight: 500px;
}
.reportML20 {
  margin-left: 20px;
}
.reportML2Per {
  margin-left: 2%;
}
.reportTextAliL {
  text-align: left;
}
.reprortBackTotal {
  background-color: rgba(87, 191, 127, 0.1);
}
.reprortBackSmS {
  background-color: rgba(244, 126, 195, 0.1);
}
.myreportAge {
  width: 100%;
  margin-right: 12px;
  padding-left: 6px;
}
.myreportGender {
  width: 100%;
  margin-right: 12px;
}
.myreportGenderWid {
  width: 120px !important;
}
.myDateMLRT {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.UIProductSearch {
  width: 238px !important;
}
.UITraceSearch {
  width: 156px !important;
}
.UIReminderSearch {
  width: 250px !important;
}
.UITelehealthSearch {
  width: 190px !important;
}
.UIinvoicePatSearch {
  width: 189px !important;
}
.UIinvoiceIdSearch {
  width: 210px !important;
}
.SearchUI {
  width: 173px;
  padding-left: 20px;
  padding-top: 8px;
}
.SearchUIAppoinmentR {
  width: 200px !important;
}
.SearchUIPatRep {
  width: 200px !important;
  margin-bottom: 0px;
  margin-top: 4px;
}
.autoCompleteSearch {
  padding-top: 10px;
  display: flex;
  position: relative;
}
.autoCompleteSearch svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
}
.autoCompleteSearch .ant-select-auto-complete {
  position: absolute;
  width: 100%;
}
.autoCompleteSearch .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 4px !important;
  height: 32px !important;
}
.autoCompleteSearch input {
  border: none !important;
}
.autoCompleteSearch .ant-select-selection-search {
  padding-left: 28px;
}
.autoCompleteSearch .ant-select-selection-placeholder {
  color: #000;
  padding-top: 1px;
}

.autoCompleteSearch
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}
.pat_Stmt_tab_bot_alt .autoCompleteSearch {
  padding-left: 0px !important;
}
.fee-schedule-head .autoCompleteSearch {
  padding-left: 0px !important;
  margin-right: 0px !important;
}
.autoCompleteSearch.card-tran-srch svg {
  margin-top: 1px;
}
.autoCompleteSearch.card-tran-srch .ant-select-selector {
  height: 34px;
  width: 100%;
}

.autoComplete34Search {
  display: flex;
  position: relative;
}
.autoComplete34Search svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
}
.autoComplete34Search .ant-select-auto-complete {
  position: absolute;
  width: 100%;
}
.autoComplete34Search .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 4px !important;
  height: 36px !important;
}
.autoComplete34Search input {
  border: none !important;
}
.autoComplete34Search .ant-select-selection-search {
  padding-left: 28px;
}
.autoComplete34Search .ant-select-selection-placeholder {
  color: #000;
  padding-top: 1px;
}

.autoComplete34Search
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}
.pat_Stmt_tab_bot_alt .autoComplete34Search {
  padding-left: 0px !important;
}
.fee-schedule-head .autoComplete34Search {
  padding-left: 0px !important;
  margin-right: 0px !important;
}
.autoComplete34Search.card-tran-srch svg {
  margin-top: 1px;
}
.autoComplete34Search.card-tran-srch .ant-select-selector {
  height: 34px;
  width: 100%;
}
/* Ramachandhiran CSS End */

/* Saranya Inline CSS Start */
.inv_bac_top {
  padding-top: 8px;
  padding-bottom: 8px;
}

.inv_sea_dr {
  padding-left: 21px;
  margin-left: -21px;
}

.inv_for_con {
  height: 38px !important;
  width: 100%;
  padding-top: 4px;
  font-size: 14px;
  font-family: DM Sans, sans-serif;
}
.inv_tab_date {
  margin-left: 6px;
}
.inv_tab_name {
  color: #2e2e2e !important;
  font-weight: 500 !important;
}

.inv_tab_inv {
  color: #245C66 !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.inv_padleft {
  padding-left: 17px !important;
}
.out_pre_load {
  text-align: left !important;
  padding: 20px !important;
}

.out_pre_arr {
  font-size: 24px !important;
  cursor: pointer;
}

.out_pre_err {
  margin-left: 0;
  text-align: left;
  position: absolute;
  bottom: -24px;
}

.out_pre_line {
  margin: 17px 16px 0px;
}

.out_pre_wen {
  padding: 5px 7px 4px 5px;
}
.out_pre_iframe {
  height: 485px !important;
}
.pro_rep_div {
  height: 125px;
  display: grid;
  place-items: center;
}
/* saranya end 17.02.2023 */
/* saranya start 17.02.2023 */
.vozo_padding {
  padding-bottom: 1px !important;
}
.vozo_margin {
  margin-top: 4px !important;
}

/* frontpage */
.m_b_9 {
  margin-bottom: 9px !important;
}
.patpor_main_div {
  display: table-cell !important;
}
/* Saranya Inline CSS End */

/* Nisha Inline CSS Start */
.m-l-3 {
  margin-left: 3px !important;
}
.uname-list-sty {
  margin-left: 6px;
  color: #151515;
  font-weight: 500;
}
.p-l-24 {
  padding-left: 24px;
}
.w-210 {
  width: 210px !important;
}
.m-r-6 {
  margin-right: 6px;
}
.w-100 {
  width: 100%;
}
.wi-121 {
  width: 121%;
}
.m-t-5 {
  margin-top: 5.5px;
}

/* Nisha Inline CSS End */
.appt-bil-stat .rp-head-title {
  padding-top: 3px !important;
}
.appt_rep_card_table .modif {
  margin-top: 4px !important;
  margin-bottom: 3px !important;
  padding-left: 2px !important;
}
.reminder_name .UIReminderSearch {
  width: 20.04% !important;
}
/* saranya billing 05/04/23 */
.valid-clse-btn .btn-primary {
  color: #2e2e2e !important;
}
.pat-list-thead1 th {
  background-color: #f1f5f8;
}
/* saranya billing 05/04/23 */

/*Ahamed 05/05/23*/
.p-b-20.prod-proc-main-cls {
  padding-bottom: 20px !important;
}
/*Ahamed 05/05/23*/
.m-t-6 {
  margin-top: 6px !important;
}
.m-t-9 {
  margin-top: 9px !important;
}
.m-l-0-imp {
  margin-left: 0px !important;
}
/* cheran */
/* Vanitha 05-05-23 */
.appt_rep_table_responsive td {
  padding: 0px 6px 0px 8px !important;
}
.appt_rep_table_responsive td:first-child,
.appt_rep_table_responsive th:first-child {
  padding-left: 20px !important;
}
.appt_rep_table_responsive th {
  padding: 0px 6px 0px 8px !important;
}
.appt_rep_checkbox_responsive div div.col-md-6,
.appt_rep_checkbox_responsive div div.col-md-2 {
  padding-left: 5px !important;
}
.appt_rep_table_responsive td:last-child {
  display: flex;
  align-items: center;
  padding: 16px 30px 6px 1px !important;
  border-bottom: none !important;
}
/* Vanitha 05-05-23 */

.grand_total {
  margin-top: 6px;
  display: flex;
}
@media only screen and (min-width: 750px) and (max-width: 1200px) {
  .con {
    padding: 10px 5px 0px 15px !important;
  }
}
/* Vanitha 08-05-23 */

.appt_rep_checkbox_responsive div div.col-md-4,
.appt_rep_checkbox_responsive div div.col-md-6,
.appt_rep_checkbox_responsive div div.col-md-2 {
  padding-right: 25px !important;
}

.p_right_30 {
  padding-right: 30px !important;
}

/* Vanitha 08-05-23 */

/* Vanitha 08-05-23*/

.remitt_tbl_resp tr:last-child {
  border-bottom: none !important;
}

/* Vanitha 08-05-23*/
/* saranya invoice 08-05-23 */
.remainderWidthSheet1 {
  width: 121% !important;
}
.inv-mar {
  margin-left: 49px;
}
.app-mar-date .form-group {
  padding-left: 0px;
}

/* saranya invoice 08-05-23 */
/*Ahamed 08-05-23*/
.autoCompleteSearch.UITraceSearch .ant-select-selector,
.autoCompleteSearch.SearchUIAppoinmentR .ant-select-selector {
  height: 34px !important;
}

.autoComplete34Search.UIinvoicePatSearch .ant-select-selector {
  height: 38px !important;
}

.last-child-brdr tr:last-child {
  border-bottom: none !important;
}
/*Ahamed 08-05-23*/
/*Ahamed 09-05-23*/
.for_autoComplete_bi.UIProductSearch .ant-select-selector {
  height: 32px !important;
}

.UIinvoiceIdSearch .ant-select-selector {
  height: 38px !important;
}
/*Ahamed 09-05-23*/
/* nisha start 09-05-2023 */
.autoCompleteSearch.UIReminderSearch .ant-select-selector,
.autoCompleteSearch.UITelehealthSearch .ant-select-selector {
  height: 34px !important;
}

/* nisha start 09-05-2023 */

/*Ahamed_16-05-2023*/
.not-take-selDt .ant-space.ant-space-vertical {
  margin-left: 0px;
  margin-top: 2px;
}
/*Ahamed_16-05-2023*/
/* Vanitha 25-09-23 */
.button_Click_event_alt {
  background-color: #245C66 !important;
  border: 1px solid #245C66 !important;
}

.button_Click_event_alt:hover {
  background-color: #16424A !important;
  border: 1px solid #245C66 !important;
}

.button_Click_event_alt:active {
  background-color: #245C66 !important;
}
.button_Click_event_alt.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #245C66 !important;
  border-color: #245C66 !important;
}

.button_click_event_white_alt {
  background-color: #fff !important;
  border: 1px solid #2c7eb5 !important;
}

.button_click_event_white_alt:hover {
  background-color: rgba(244, 246, 249, 0.75) !important;
}

.button_click_event_white_alt:active {
  background-color: rgba(44, 123, 229, 0.1) !important;
}
.as-flag-align {
  text-align: left;
}
/* Vanitha 25-09-23 */
/*Ahamed 29-11-23*/
.antd-dd-pro_repo .ant-select-selector {
  margin-left: 0 !important;
}
.pat-repo-gendr .ant-select-item-option-content,
.repo-inv-stat .ant-select-item-option-content,
.ellipsis-unset .ant-select-item-option-content {
  text-overflow: unset;
  padding-left: 3px;
}
.antd-dd-pat_repo .ant-select-selection-item,
.ovrflow-unset .ant-select-selection-item {
  text-overflow: unset;
}
.antd-dd-inv_repo .ant-select-selection-item {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 85px;
  margin-top: 2px;
}
.repo-inv-stat {
  padding-left: 9px !important;
  padding-right: 9px !important;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
/*Ahamed 29-11-23*/
/*Saranya 11-12-2023*/
.ivoicetablePartialcenter {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DatePic_input #date1 {
  padding-left: 0px !important;
}
.inventory_datepic #date1 {
  padding-left: 0px !important;
}
/*Saranya 11-12-2023*/
.p-b-6 {
  padding-bottom: 6px !important ;
}

.m-b-16-imp {
  margin-bottom: 16px !important ;
}
.app_custom .modal-dialog {
  top: 15%;
}

.report_date_width {
  width: 238px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}
.bill-ins-filter-block,
.tele-rep-filter-block {
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 20px;
}
